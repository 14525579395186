import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames'
import { bindActionCreators } from 'redux';
import { userContextActions } from '../../store/userContextStore'
import { Link } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseQuery, isObjectPathDefined, apiPost, getHostApi } from '../../store/apiWrapper';

import InputControl from '../common/inputControl';

class LostPwdPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username:'', email:'', token:'', loading:false, error:false, stage:1, completed:false
        };

        const q = parseQuery(window.location.search);
        if(isObjectPathDefined(q, 'u') && isObjectPathDefined(q, 'rpt')) {
            this.state.loading = true;
            this.resetPwdConfirm(q.u, q.rpt);
            this.state.stage = 2;
        }
    }
    
    changeEvent = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    resetPwdConfirm = (token, user) => {
        apiPost(getHostApi() + '/api/account/confirm-reset-pwd', undefined, { token, user }, 
        (res) => this.setState({ error:!res.data.success, message:res.data.errorMessage}),
        (err) => this.setState({ error:true, message:err}), 
        () => this.setState({loading:false, completed:true}));
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading:true, error:false, message:undefined});
        apiPost(getHostApi() + '/api/account/reset-pwd', undefined, {userName:this.state.username, email:this.state.email}, 
        (res) => this.setState({ error:!res.data.success, message:res.data.errorMessage}),
        (err) => this.setState({ error:true, message:err}), 
        () => this.setState({loading:false, completed:true}));
    }

    renderStageOne = () => {
        if(this.state.completed) {
            return (
                <div className={classnames({'notification':true, 'is-primary':!this.state.error, 'is-danger':this.state.error})} dangerouslySetInnerHTML={{__html: this.state.message}}></div>
            );
        }

        return (
            <React.Fragment>
                <h2 className="title is-3 mt-25">{this.props.translate('PwdReset.FormCaption')}</h2>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <table className="table full-width no-border">
                        <tbody>
                            <tr>
                                <td style={{textAlign:"right", whiteSpace:"nowrap"}}><label className="label">{this.props.translate('PwdReset.Username')}</label></td>
                                <td style={{width:"100%"}}><InputControl value={this.state.username} type="text" fieldName="username" onChange={this.changeEvent} disabled={this.state.loading} /></td>
                            </tr>
                            <tr>
                                <td style={{textAlign:"right", whiteSpace:"nowrap"}}><label className="label">{this.props.translate('PwdReset.UserEmail')}</label></td>
                                <td style={{width:"100%"}}><InputControl value={this.state.email} type="email" fieldName="email" onChange={this.changeEvent} disabled={this.state.loading} /></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <p className="control">
                                        { !this.state.loading && (<input type="submit" value={this.props.translate('PwdReset.BtnReset')} className="button is-primary is-outlined is-pulled-right" disabled={this.state.loading} />) }
                                        { this.state.loading && (<span className="button is-primary is-outlined is-pulled-right"><FontAwesomeIcon icon="cog" spin /></span>)}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </React.Fragment>
        );
    };

    renderStageTwo = () => {
        return (
            <div>
                { this.state.completed && <React.Fragment> <div className={classnames({'notification':true, 'is-primary':!this.state.error, 'is-danger':this.state.error})} dangerouslySetInnerHTML={{__html: this.state.message}}></div><Link to="/login">{this.props.translate("Login.UserLogin")}</Link></React.Fragment>}
                { !this.state.completed && this.state.loading && (<span>processing <FontAwesomeIcon icon="cog" spin /></span>) }
            </div>
        );
    }

    render() { 
        return (
            <div className="middle-container box-login">
                { this.state.stage === 2 && this.renderStageTwo() }
                { this.state.stage !== 2 && this.renderStageOne() }
            </div>
        );
        
    }
}

export default withLocalize(connect(
    state => state.userContext,
    dispatch => bindActionCreators(userContextActions, dispatch)
)(LostPwdPage));