import React from 'react';
import classnames from 'classnames'

const InputControl = ({fieldName, value, type, onChange, error, placeholder, disabled}) => {
    return (
        <React.Fragment>
            <div className="control">
                <input onChange={onChange} value={value} type={type} name={fieldName} placeholder={placeholder}
                    className={classnames({'input':true, 'is-danger':error})} disabled={disabled}/>
            </div>
            { error && <p className="help is-danger">{error}</p>}
        </React.Fragment>
    );
}

export default InputControl;
