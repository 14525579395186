import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from "react-localize-redux";
import { bindActionCreators } from 'redux';
import { userContextActions } from '../../store/userContextStore';
import { dashboardContextActions } from '../../store/dashboardStore';
import { writeErr, writeDebug } from '../common/logger';

import TopNavBar from '../TopNavBar';

class DashboardLayout extends React.Component {
    constructor(props) {
        super(props); 

        var selectApp = JSON.parse(localStorage.getItem("selectApp"));
        if(selectApp) {
            this.props.updateSettings({appId: selectApp.key});
        }
        
        this.state = {
            appGroups: this.props.userContext.authentication.appIds,
            selectApp: selectApp
        }

        this.handleUpdateSelectApp = this.handleUpdateSelectApp.bind(this);

        let lang = this.props.userContext.authentication.profile.agentLanguage;
        // check if localize is downloaded
        let downloadLocalize = true;
        let array = this.props.localize.languages;
        for(let i = 0; i < array.length; i++){
            if(array[i].code === lang && this.props.localize.translations[Object.keys(this.props.localize.translations)[0]][i]) {
                downloadLocalize = false;
                break;
            }
        }

        if(downloadLocalize) {
            this.props.switchTranslation(lang, this.props.addTranslationForLanguage, () => {
                this.props.setActiveLanguage(lang);                    
            });
        } else {
            this.props.setActiveLanguage(lang);
        }
    }

    handleUpdateSelectApp(data){
        localStorage.setItem('selectApp', JSON.stringify(data));
        this.props.updateSettings({appId: data.key, loadingPage: true});
        writeDebug(`Selected app: ${data.app},  id: ${data.key}`);
        this.setState({selectApp: data}, () => {
            this.props.loadCustomerData(this.props.loadLeftNavBarData);
            this.props.hideQuickView();
        });        
    }

    componentDidMount() {            
        if(this.state.selectApp === undefined || this.state.selectApp === null){
            var keys = Object.keys(this.state.appGroups); 

            if(keys.length > 1) {
                this.props.redirectToPage("/select-app");
            } else if(keys.length === 1) {
                var data = { key: keys[0], app: this.state.appGroups[keys[0]]}
                this.handleUpdateSelectApp(data);                
            } else {
                writeErr("User has no assigned to any application.");
                this.props.logout();
            }
        } else {
            this.props.loadCustomerData(this.props.loadLeftNavBarData);
        }
    }

    render(){
        const childrenWithProps = React.Children.map(this.props.children, child =>
            React.cloneElement(child, { selectApp: this.state.selectApp })
        );

        return (
            <div className="main-container">        
                <TopNavBar updateSelectApp={this.handleUpdateSelectApp} selectApp={this.state.selectApp}></TopNavBar>     
                <div className="main-body">
                    {childrenWithProps}
                </div>                
            </div>
        );
    }
}

export default withLocalize(connect(
    state => ({userContext: state.userContext, localize: state.localize}),
    dispatch => bindActionCreators(Object.assign({}, userContextActions, dashboardContextActions), dispatch)
)(DashboardLayout));