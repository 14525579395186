import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withLocalize } from "react-localize-redux";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Accordion from '../../common/accordion/Accordion';
import { dashboardContextActions } from '../../../store/dashboardStore';
import MultiSelectComponent from '../../common/multiSelect/MultiSelectComponent';
import SelectComponent from '../../common/select/SelectComponent';

class UserForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: Object.assign({}, this.props.user)
        }
    }

    render() {
        let user = this.state.user;
        if(user.created !== undefined) {
            user.created = <React.Fragment><Moment format="L">{user.created}</Moment><Moment format="LT">{user.created}</Moment></React.Fragment>
        }
        if(user.modified !== undefined && user.modified !== null) {
            user.modified = <React.Fragment><Moment format="L">{user.modified}</Moment><Moment format="LT">{user.modified}</Moment></React.Fragment>
        }     
        
        let languages = this.props.languages.map((e) => {return ( { value: e["code"], label: e["name"] } )});
        let myProfile = this.props.myProfile || false;

        user.oldRoles = user.roles;

        return (            
                <Formik
                    initialValues={user}
                    validate={values => {
                        let errors = {};
                        //TODO add validation
                        if(!values.name){
                            errors.name = this.props.translate('Common.RequiredField');
                        }

                        if(!values.surname){
                            errors.surname = this.props.translate('Common.RequiredField');
                        }

                        if(!values.userName){
                            errors.userName = this.props.translate('Common.RequiredField');
                        }

                        if (!values.email) {
                            errors.email = this.props.translate('Common.RequiredField');
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = this.props.translate('Common.InvalidEmail');
                        }

                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting }) => {
                        let user = {
                            id: values["id"],
                            userName: values["userName"],
                            name: values["name"],
                            surname: values["surname"],
                            email: values["email"],
                            phone: values["phone"],
                            description: values["description"],
                            active: values["active"],
                            roles: values["roles"].map((e) => (parseInt(e))),                            
                            oldRoles: values["oldRoles"].map((e) => (parseInt(e))),                            
                            defaultLanguage: values["defaultLanguage"][0]
                        };  

                        if(this.props.settings.useBranch) {
                            user.branchId = values["branchId"];
                        } else if(this.props.settings.useOrganization) {
                            user.organizationId = values["organizationId"];
                        }

                        if(user.id === 0) {
                            this.props.createUser(user, setSubmitting, this.props.callback);
                        } else if(user.id > 0) {
                            this.props.updateUser(user, setSubmitting, this.props.callback);
                        }
                    }}                    

                    render={({ errors, values, isSubmitting, setFieldValue, handleSubmit }) => (
                        <React.Fragment>
                            <Form className="my-form light">
                                <div className="header">
                                    <div className="buttons top-buttons">
                                        <button className="button is-white" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                                            <i className="fa fa-check"></i>
                                            OK
                                        </button>
                                        <button className="button is-white" type="button" onClick={this.props.hideQuickView} disabled={isSubmitting}>
                                            <i className="fa fa-times"></i>
                                            { this.props.translate('Common.Button.Cancel') }
                                        </button>
                                    </div>
                                    <div className="display-flex">
                                        <h2 className="subtitle is-3 width-85"> 
                                            { values.id !== 0 ? this.props.translate('User.EditTitle', {username: values.userName}) : this.props.translate('User.CreateTitle', {username: values.userName}) }
                                        </h2>
                                        {myProfile ?  
                                            <Field type="hidden" name="active"/>
                                        : 
                                            <div className="field center">                                      
                                                <Field name="active" type="checkbox" checked={values.active} className={`is-checkradio is-primary`} id="checkbox-active" />
                                                <label onClick={() =>setFieldValue("active", !values.active)} className="label" htmlFor="checkbox-active">{ this.props.translate('Common.Active') }</label>
                                            </div>
                                        }                                        
                                    </div>
                                </div>
                                <div className="form">
                                    <PerfectScrollbar ref={(ref) => this.scrollBarRef = ref} options={{ wheelSpeed: 0.6, suppressScrollX: true }}>
                                        <div className="form-content">                                           
                                            <Field type="hidden" name="id"></Field>                                  

                                            {values.id === 0 ? 
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">{ this.props.translate('Common.Login') }</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <p className="control">
                                                                <Field type="text" name="userName" className={`input ${errors.userName ? "is-danger" : ""}`} />
                                                            </p>   
                                                            <ErrorMessage name="userName" component="p" className="help is-danger" />                         
                                                        </div>
                                                    </div>                            
                                                </div>
                                            : null}

                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal">
                                                    <label className="label">{ this.props.translate('Common.FirstName') }</label>
                                                </div>
                                                <div className="field-body">
                                                    <div className="field">
                                                        <p className="control">
                                                            <Field type="text" name="name" className={`input ${errors.name ? "is-danger" : ""}`} />
                                                        </p>   
                                                        <ErrorMessage name="name" component="p" className="help is-danger" />                         
                                                    </div>
                                                </div>                            
                                            </div> 

                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal">
                                                    <label className="label">{ this.props.translate('Common.Surname') }</label>
                                                </div>
                                                <div className="field-body">
                                                    <div className="field">
                                                        <p className="control">
                                                            <Field type="text" name="surname" className={`input ${errors.surname ? "is-danger" : ""}`} />
                                                        </p>   
                                                        <ErrorMessage name="surname" component="p" className="help is-danger" />                         
                                                    </div>
                                                </div>                            
                                            </div> 

                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal">
                                                    <label className="label">{ this.props.translate('Common.Email') }</label>
                                                </div>
                                                <div className="field-body">
                                                    <div className="field">
                                                        <p className="control">
                                                            <Field type="email" name="email" className={`input ${errors.email ? "is-danger" : ""}`} />
                                                        </p>   
                                                        <ErrorMessage name="email" component="p" className="help is-danger" />                         
                                                    </div>
                                                </div>                            
                                            </div> 

                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal">
                                                    <label className="label">{ this.props.translate('Common.Phone') }</label>
                                                </div>
                                                <div className="field-body">
                                                    <div className="field">
                                                        <p className="control">
                                                            <Field type="text" name="phone" className={`input ${errors.phone ? "is-danger" : ""}`} />
                                                        </p>   
                                                        <ErrorMessage name="phone" component="p" className="help is-danger" />                         
                                                    </div>
                                                </div>                            
                                            </div>                                

                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal">
                                                    <label className="label">{ this.props.translate('User.Languange') }</label>
                                                </div>
                                                <div className="field-body">
                                                    <div className="field">
                                                        <div className="control">
                                                            <SelectComponent name="defaultLanguage" options={languages} value={values.defaultLanguage} handleChange={(value) => setFieldValue("defaultLanguage", value)}  />
                                                        </div>                         
                                                    </div>
                                                </div>                            
                                            </div>
                                            {this.props.settings.useBranch ? 
                                                values.id > 0 ?                                     
                                                    <div className="field is-horizontal">
                                                        <Field type="hidden" name="branchId"></Field>

                                                        <div className="field-label is-normal">
                                                            <label className="label">{ this.props.translate('Common.Branch') }</label>
                                                        </div>
                                                        <div className="field-body">
                                                            <div className="field">
                                                                <p className="control">
                                                                    <Field readOnly type="text" name="branchName" className={`input`} />
                                                                </p>                        
                                                            </div>
                                                        </div>                            
                                                    </div>
                                                :
                                                    <div className="field is-horizontal">
                                                        <Field type="hidden" name="organizationId"></Field> 

                                                        <div className="field-label is-normal">
                                                            <label className="label">{ this.props.translate('Common.Branch') }</label>
                                                        </div>
                                                        <div className="field-body">
                                                            <div className="field">
                                                                <div className="control">
                                                                    <SelectComponent options={values.branchList} className={`${errors.branchId ? "is-danger" : ""}`}
                                                                        name="branchId" value={[`${values.branchId.toString()}_${values.organizationId.toString()}`]} handleChange={(value) => {
                                                                            let splitValue = value[0].split("_");
                                                                            setFieldValue("branchId", parseInt(splitValue[0])); 
                                                                            values.organizationId = parseInt(splitValue[1]);                                                     
                                                                            setFieldValue("organizationId", parseInt(splitValue[1]));  
                                                                            values.roles = [];
                                                                            setFieldValue("roles", []);                                                    
                                                                        }}/>
                                                                </div>                      
                                                            </div>
                                                        </div>                            
                                                    </div>
                                                
                                                : null}

                                            {this.props.settings.useOrganization ?
                                                values.id > 0 && Object.keys(this.props.customerData.organizations).length > 1 ? 
                                                    <div className="field is-horizontal">
                                                        <Field type="hidden" name="organizationId"></Field>

                                                        <div className="field-label is-normal">
                                                            <label className="label">{ this.props.translate('Common.Organization') }</label>
                                                        </div>
                                                        <div className="field-body">
                                                            <div className="field">
                                                                <p className="control">
                                                                    <Field readOnly type="text" name="organizationName" className={`input`} />
                                                                </p>                        
                                                            </div>
                                                        </div>                            
                                                    </div>
                                                :
                                                    values.organizationList ?
                                                        <div className="field is-horizontal">                                        
                                                            <div className="field-label is-normal">
                                                                <label className="label">{ this.props.translate('Common.Organization') }</label>
                                                            </div>
                                                            <div className="field-body">
                                                                <div className="field">
                                                                    <div className="control">
                                                                        <SelectComponent options={values.organizationList} className={`${errors.organizationId ? "is-danger" : ""}`}
                                                                            name="organizationId" value={values.organizationId ? [values.organizationId.toString()] : []} handleChange={(value) => {
                                                                                values.organizationId = parseInt(value);
                                                                                setFieldValue("organizationId", parseInt(value));
                                                                                values.roles = [];
                                                                                setFieldValue("roles", []);
                                                                            }} />
                                                                    </div>                      
                                                                </div>
                                                            </div>                            
                                                        </div>
                                                    : 
                                                        <Field type="hidden" name="organizationId"></Field>
                                            : null}

                                            <Field type="hidden" name="oldRoles"></Field>
                                            { myProfile ? 
                                                <>
                                                    <Field type="hidden" name="roles"/>
                                                    <Field type="hidden" name="description"/>
                                                </>
                                            :<> 
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">{ this.props.translate('User.Role') }</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <div className="control">
                                                                <MultiSelectComponent disabled={values.rolesOptions.length === 0 && (values.organizationId === undefined || values.organizationId === 0) && (this.props.settings.useBranch || this.props.settings.useOrganization)}                                                    
                                                                    name="roles" value={values.roles} options={values.rolesOptions} handleChange={(value) => setFieldValue("roles", value)} loadOptions={() => {
                                                                            if(values.id === 0 && values.organizationId && values.roles.length === 0){
                                                                                let rolesOptions = [];
                                                                                if(this.props.customerData.organizations[values.organizationId].roles.length > 0) {
                                                                                    rolesOptions = this.props.customerData.organizations[values.organizationId].roles
                                                                                        .map((roleId) => ( {value: roleId.toString(), label: this.props.customerData.roles[roleId].name} ));
                                                                                }
                                                                                setFieldValue("rolesOptions", rolesOptions);
                                                                            }                                                            
                                                                        }} />
                                                            </div>                         
                                                        </div>
                                                    </div>                            
                                                </div> 
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">{ this.props.translate('Common.Description') }</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <p className="control">
                                                                <Field onClick={() => this.scrollBarRef.updateScroll()} component="textarea" name="description" className={`textarea ${errors.description ? "is-danger" : ""}`} />
                                                            </p>   
                                                            <ErrorMessage name="description" component="p" className="help is-danger" />                         
                                                        </div>
                                                    </div>                            
                                                </div>
                                            </>}

                                            {values.id > 0 && (values.created || values.createdBy || values.modified || values.modifiedBy) ?
                                                <div className="info">
                                                    <Accordion onToggled={(active) => this.scrollBarRef.updateScroll()} header={ this.props.translate('Branch.ShortAuditLog')} headerIcon="fa fa-info-circle"> 
                                                        {values.created ?
                                                            <p>{ this.props.translate('User.Created', {date: values.created}) }</p>
                                                        : 
                                                            null
                                                        }
                                                        {values.createdBy ? 
                                                            <p>{ this.props.translate('User.CreatedBy', {user: values.createdBy}) }</p>
                                                        : 
                                                            null
                                                        }
                                                        {values.modified ? 
                                                            <p>{ this.props.translate('User.Edited', {date: values.modified}) }</p>
                                                        : 
                                                            null
                                                        }
                                                        {values.modifiedBy ? 
                                                            <p>{ this.props.translate('User.EditedBy', {user: values.modifiedBy}) }</p>
                                                        : 
                                                            null
                                                        }
                                                    </Accordion>
                                                </div>
                                            : 
                                                null
                                            } 
                                        </div> 
                                    </PerfectScrollbar> 
                                </div>
                            </Form>                            
                        </React.Fragment>
                    )}
                />
        );
    }
}

UserForm.propTypes = {
    user: PropTypes.object.isRequired
};

export default withLocalize(connect(
    state => state.dashboardContext,
    dispatch => bindActionCreators(dashboardContextActions, dispatch)
)(UserForm));