import React from 'react';
import { withLocalize } from "react-localize-redux";
import classnames from 'classnames'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { apiPost, getHostApi } from '../../store/apiWrapper';
import { Formik, Form, Field } from 'formik';

import { userContextActions } from '../../store/userContextStore'

// style
import './LoginPage.scss';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            username: '', pwd: '', showPwd: false, missing_username: false, missing_pwd: false,
            newPswd:'', newPswd2:'', error:false, message:undefined
        };
    }

    handleShowPwd() {
        this.setState({ showPwd: !this.state.showPwd })
    }
    
    changeEvent(event) {
        this.setState({[event.target.name]: event.target.value, ['missing_'+event.target.name]:event.target.value.length === 0});
    }

    isFormValid() {
        return this.fieldsFilled()  && this.pswdMatch();
    }

    fieldsFilled(){
        return this.state.username !== '' && this.state.pswd !== '' && this.state.newPswd !== '' && this.state.newPswd2 !== '';
    }

    pswdMatch() {
        return this.state.newPswd === this.state.newPswd2;
    }

    handleSubmit(event, type) {   
        event.preventDefault();
        if(type === 'login'){
            this.props.login(this.state.username, this.state.pwd, window.location.href);
            return;           
        }
        if(type === 'change'){
            let model = { username:this.state.username, oldPswd:this.state.pwd, newPswd:this.state.newPswd };
            this.setState({loading:true, error:false, message:undefined, pwd:'', newPswd:'', newPswd2:'',missing_username: true, missing_pwd: true,});
            apiPost(getHostApi() + '/api/account/change-pwd', undefined, model, 
                (res) => { 
                    if(!res.data.success) {
                        this.setState({ error:true, message:res.data.errorMessage});
                    } else {
                        this.props.login(this.state.username, model.newPswd, window.location.href);
                    }
                },
                (err) => this.setState({ error:true, message:err}), 
                () => this.setState({loading:false, completed:true})
            );
            return;
        }
    }

    render () {
        if(this.props.authentication.isAuthorized === false && this.props.authentication.errorMessage === 'ExpiredPassword') {
            return this.renderPwdChange();
        }

        return this.renderLoginForm();
    }

    renderPwdChange() {
        return (
            <div className="middle-container box-login">
                <form onSubmit={(e) => this.handleSubmit(e, 'change')}>
                    <div className="columns">
                        <div className="column">
                            <h1 className="subtitle is-4">{ this.props.translate('PswdChange.FormCaption')}</h1>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <div className={classnames({"notification":true, "is-warning":!this.state.error, "is-danger":this.state.error })}>{ !this.state.error ? this.props.translate('Login.PasswordExpired') : this.state.message }</div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <div className="field">
                                <p className="control has-icons-left has-icons-right">
                                    <input className="input" type="text" placeholder={this.props.translate('Login.Username')} disabled={true} name='username' value={this.state.username} onChange={(e) => this.changeEvent(e)} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="user" /></span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input className="input" type="password" placeholder={this.props.translate('Login.Password')} disabled={this.props.loading} name="pwd" value={this.state.pwd} onChange={(e) => this.changeEvent(e)} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="lock" /></span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input className={classnames({"input":true, "is-danger":this.fieldsFilled() && !this.pswdMatch()})} type="password" placeholder={this.props.translate('Login.Password')} disabled={this.state.loading} name="newPswd" value={this.state.newPswd} onChange={(e) => this.changeEvent(e)} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="lock" /></span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input className={classnames({"input":true, "is-danger":this.fieldsFilled() && !this.pswdMatch()})} type="password" placeholder={this.props.translate('Login.Password')} disabled={this.state.loading} name="newPswd2" value={this.state.newPswd2} onChange={(e) => this.changeEvent(e)} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="lock" /></span>
                                </p>
                                { this.fieldsFilled() && !this.pswdMatch() && <p className="help is-danger">{this.props.translate('PswdChange.PswdsDontMatch')}</p> }
                            </div>
                            <div className="field columns">
                                <div className="column">
                                    <p className="control">
                                        {!this.state.loading && (<input type="submit" value={this.props.translate('PswdChange.SubmitBtn')} className="button is-primary is-outlined is-pulled-right" disabled={this.state.loading || !this.isFormValid()} />)}
                                        {this.state.loading && (<span className="button is-primary is-outlined is-pulled-right btn-login-loading"><FontAwesomeIcon icon="cog" spin /></span>)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    renderLoginForm() {
        return (
            <div className="middle-container box-login">
                
                <div className="field mt-25 mb-25">
                    <h2 className="title is-4"><i className="fa fa-user"></i>{this.props.translate("Login.UserLogin")}</h2>
                </div>

                <Formik initialValues={{ userName: "", password: "" }}
                    validate={values => {
                        let errors = {};
                        if(!values.userName){
                            errors.userName = this.props.translate('Common.RequiredField');
                        }

                        if(!values.password){
                            errors.password = this.props.translate('Common.RequiredField');
                        }                       

                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting }) => {
                        this.props.login(values["userName"], values["password"], window.location.href);
                        setSubmitting(false);
                    }}
                    
                    render={({ errors, isSubmitting, handleSubmit }) => (
                        <Form className="login-form">
                            <div className="field">
                                <p className="control has-icons-left">                                    
                                    <Field placeholder={this.props.translate("Login.Username")} type="text" name="userName" className={`input ${errors.userName ? "missing-input" : ""}`} />
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="user" /></span>
                                </p>
                            </div>

                            <div className="field has-addons">
                                <p className="control has-icons-left is-expanded">
                                    <Field placeholder={this.props.translate("Login.Password")} type={this.state.showPwd ? "text" : "password"} name="password" className={`input ${errors.password ? "missing-input" : ""}`} />                                   
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="key" /></span>                            
                                </p>
                                <p className="control">
                                    <span className={`button display-password ${this.state.missing_pwd ? "missing-input" : ""}`} onClick={() => this.handleShowPwd()}><i className={`fa fa-eye${!this.state.showPwd ? "" : "-slash"}`}></i></span>
                                </p>
                            </div>

                            <div className="login-buttons">                                                           
                                <button onClick={handleSubmit} disabled={isSubmitting} type="submit" className={`button is-crif pull-right btn-login ${this.props.authentication.loading ? "is-loading" : ""}`}>                                        
                                    <FontAwesomeIcon icon="lock" className="icon-lock" />
                                    {this.props.translate("Login.Login")}                                    
                                </button>                                
                                <div className="lost-password-container">
                                    <Link className="lost-password" to="/lost-password">{this.props.translate("Login.LostPassword")}</Link>
                                </div>
                                <div className="spacer"></div>
                            </div>
                        </Form>
                    )}/>
            </div>
        );
    };
}

export default withLocalize(connect(
    state => state.userContext,
    dispatch => bindActionCreators(userContextActions, dispatch)
)(LoginPage));