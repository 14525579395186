import { app } from '../components/common/appHelper';

import CribisManager from './AppManagers/CribisManager';
import NeosManager from './AppManagers/NeosManager';
import IRatingManager from './AppManagers/IRatingManager';

export const appManager = {
    props: null,
    cribisManager: null,
    neosManager: null,
    iRatingManager: null,
    init: (props) => {
        appManager.props = props;

        appManager.cribisManager = new CribisManager(props);
        appManager.neosManager = new NeosManager(props);
        appManager.iRatingManager = new IRatingManager(props);
    },
    getUserContextMenu: (menuId) => {
        let contextMenu = null;

        if(appManager.props.settings.appId === app.MultiCribis) {           
            contextMenu = appManager.cribisManager.getUserContextMenu(menuId);
        } else if(appManager.props.settings.appId === app.NEOS) {
            contextMenu = appManager.neosManager.getUserContextMenu(menuId);
        } else if(appManager.props.settings.appId === app.IRating) {
            contextMenu = appManager.iRatingManager.getUserContextMenu(menuId);
        }

        return contextMenu;
    }
};