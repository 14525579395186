import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { localizeReducer } from "react-localize-redux";

import * as UserContext from './userContextStore';
import * as DashboardContext from './dashboardStore';
import * as ReportContext from './reportStore';

export default function configureStore (history, initialState) {
  const reducers = {
      userContext: UserContext.reducer,
      localize: localizeReducer,
      dashboardContext: DashboardContext.reducer,
      reportContext: ReportContext.reducer
  };

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
