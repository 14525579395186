import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

// style
import './QuickView.scss';

class QuickView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {         
        };
    }

    render() {
        return (
            <div className={`quickview-back ${this.props.show ? "is-active" : ""}`}>                
                <div className={`quickview`}>                    
                    <div className="quickview-content">
                        <PerfectScrollbar className="quickview-fullwidth" ref={(ref) => this._scrollBarRef = ref} options={{ wheelSpeed: 0.6, suppressScrollX: true }}>
                            {this.props.showHeader === undefined || this.props.showHeader ? 
                                <div className="quickview-header">
                                    <div onClick={(e) => this.handleClickClose(e)} className="quickview-button quickview-close"><i className="fa fa-times"></i></div>
                                </div>
                            :
                                null
                            }                         
                            <div className="quickview-body">                        
                                {this.props.children}                       
                            </div>
                        </PerfectScrollbar> 
                    </div>            
                </div>                
            </div>            
        );
    }
}

QuickView.propTypes = {
    show: PropTypes.bool.isRequired,
    showHeader: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default QuickView;