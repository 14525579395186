import React from 'react';
import { Menu, Item, Separator, Submenu, animation  } from 'react-contexify';

import { page } from '../../components/common/urlHelper';
import { Event, EventManager } from '../../managers/EventManager';

class CribisManager {
    constructor(props) {
        this.props = props;
    }

    getUserContextMenu = (menuId) => {
        return (
            <Menu id={menuId} animation={animation.pop} onHidden={() => EventManager.callGlobalEvent(Event.hideContextMenu)}>
                <Item onClick={({props}) => this.props.showUser(props.id)}><i className="fa fa-pencil"></i>{this.props.translate("Common.Button.Update")}</Item>                                
                <Submenu arrow={<i className="fa fa-angle-right"></i>} label={<><i className="fa fa-key"></i>{this.props.translate("Common.Button.Security")}</>}>
                    <Item onClick={({props}) => {
                        let user = this.props.customerData.users[props.id];
                        this.props.resetPassword(user.userName, user.email);
                    }}>{this.props.translate("User.Button.ResetSecurity")}</Item>
                </Submenu>
                <Submenu arrow={<i className="fa fa-angle-right"></i>} label={<><i className="fa fa-area-chart"></i>{this.props.translate("Common.Button.Reports")}</>}>
                    <Item onClick={({props}) => this.props.redirectToPage(page.getReports(page.reportTypes.cribisReports, {userId: props.id}))}>{this.props.translate("Report.Title.CribisReports")}</Item>
                    <Item onClick={({props}) => this.props.redirectToPage(page.getReports(page.reportTypes.loginHistory, {userId: props.id}))}>{this.props.translate("Report.Title.LoginHistory")}</Item>
                </Submenu>
                <Separator/>
                <Item onClick={({props}) => this.props.deleteUser(props.id)}><i className="fa fa-trash"></i>{this.props.translate("Common.Button.Delete")}</Item>
            </Menu>
        )
    }
};

export default CribisManager;