import React from 'react';
import PropTypes from 'prop-types';

import './Accordion.scss';

class Accordion extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            active: false
        }

        this.handleToggleActive = this.handleToggleActive.bind(this);
    }

    handleToggleActive() {
        this.setState({active: !this.state.active}, () => {
            if(this.props.onToggled) {
                this.props.onToggled(this.state.active);
            }
        });
    }

    render() {
        return (        
        <div id="aspect-content">
            <div className="aspect-tab ">
                <input id="item-18" type="checkbox" readOnly checked={this.state.active} className="aspect-input" name="aspect"/>
                <label htmlFor="item-18" className="aspect-label" onClick={() => this.handleToggleActive()}></label>
                <div className="aspect-content">
                    <div className="aspect-info">                        
                        <span className="aspect-name">{ this.props.headerIcon !== undefined ? <i className={this.props.headerIcon}></i> : null }{this.props.header}</span>
                    </div>                    
                </div>
                <div className="aspect-tab-content">
                    <div className="sentiment-wrapper">
                        <div>
                            {this.props.children}
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

Accordion.propTypes = {
    header: PropTypes.string.isRequired,
    onToggled: PropTypes.func
};

export default Accordion;