import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from 'react-sm-select';
import { withLocalize } from "react-localize-redux";

import './MultiSelectComponent.scss';

class MultiSelectComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: []
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.value || nextProps.value === []) {
            this.setState({ value: nextProps.value }, () => {
                if(this.props.loadOptions) {
                    this.props.loadOptions();
                } 
            });                     
        }
    }

    formatOption(checked, option, isSingle) {
        if(option["description"] !== undefined) {
            return (<div className="option-with-description">
                <p className="option-label"><i className={`fa fa-check ${checked ? "check" : ""}`}></i>{option["label"]}</p>
                <p className="option-description">{option["description"]}</p>
            </div>)
        } else {
            return <p className="option-label"><i className={`fa fa-check ${checked ? "check" : ""}`}></i>{option["label"]}</p>;
        }
    }

    render(){
        return (<div className={this.props.className}>
            {this.props.disabled !== undefined && this.props.disabled ?
                <input className="input" type="text" disabled placeholder={this.props.disablePlaceholder}/>
            :
                <MultiSelect
                    Option={({checked, option, isSingle}) => {return this.formatOption(checked, option, isSingle)}}
                    id={this.props.id !== undefined ? this.props.id : null}
                    mode="tags"
                    enableSearch={true}
                    resetable={true}
                    options={this.props.options}
                    value={this.props.value}
                    onChange={value => this.props.handleChange(value)}
                    searchPlaceholder={ this.props.translate("Common.Search") }
                    valuePlaceholder={ this.props.translate("Common.Select") }/>
            }
            
        </div>);
    }
}

MultiSelectComponent.propTypes = {
    id: PropTypes.string,
    disablePlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired
};

export default withLocalize(MultiSelectComponent);