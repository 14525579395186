import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from "react-localize-redux";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { contextMenu } from 'react-contexify';

import { writeDebug } from '../../common/logger';
import CollapseList from './collapseList/CollapseList';
import { dashboardContextActions } from '../../../store/dashboardStore';
import { userContextActions } from '../../../store/userContextStore';
import { appManager } from '../../../managers/AppManager';

// style
import './LeftNavBar.scss';

class LeftNavBar extends React.Component {
    userContextMenu = 'userContextMenu';

    constructor(props) {
        super(props);

        this.state = {
            selectedItemType: null,
            selectedItemId: null,
            searchText: "",
            loading: false
        };

        this.handleClickItem = this.handleClickItem.bind(this);
        this.handleDoubleClickItem = this.handleDoubleClickItem.bind(this);
        this.handleCollapse = this.handleCollapse.bind(this);
        this.handleChangeSearchText = this.handleChangeSearchText.bind(this);        
    }

    // UNSAFE_componentWillMount = props => {
    //     this.searchTimeout = null
    // }

    componentDidMount() {
        this.searchTimeout = null;
        this.setState({searchText: this.props.leftNavBarData.searchText});
    }

    handleClickItem(headerType, headerId, header, type, id, name){
        this._scrollBarRef.updateScroll();
        writeDebug(`change params headerType: ${headerType}, headerId: ${headerId}, header: ${header} ||| type: ${type}, id: ${id}, name: ${name}`);
        this.setState({selectedItemType: type, selectedItemId: id}, () => {
            switch (type) {
                case "branch":
                    this.props.selectBodyType("user");
                    this.props.changeBodyParams({OrganizationId: headerId, BranchId: id, RoleId: 0});
                    this.props.changeSelectedParams({branchId: id, organizationId: headerId}, true);  
                    break;
                case "organization":
                    if(this.props.settings.useOrganization){
                        this.props.selectBodyType("user");
                    } else if (this.props.settings.useBranch) {
                        this.props.selectBodyType("branch");
                    }
                    
                    this.props.changeBodyParams({OrganizationId: id, BranchId: 0, RoleId: 0});
                    this.props.changeSelectedParams({organizationId: id}, true);
                    break;
                case "organizations":
                    this.props.selectBodyType("organization");
                    this.props.changeBodyParams({OrganizationId: 0, BranchId: 0, RoleId: 0});
                    this.props.changeSelectedParams({organizationId: 0}, true);
                    break;
                case "user":
                    writeDebug("Not implemented click to user");
                    break;
                case "users":
                    this.props.selectBodyType("user");
                    this.props.changeBodyParams({OrganizationId: 0, BranchId: 0, RoleId: 0});
                    this.props.changeSelectedParams({roleId: 0}, true);
                    break;
                case "role":
                    this.props.selectBodyType("user");
                    this.props.changeBodyParams({OrganizationId: 0, BranchId: 0, RoleId: id});
                    this.props.changeSelectedParams({roleId: id}, true);
                    break;
                case "roles":
                    this.props.selectBodyType("user");
                    this.props.changeBodyParams({OrganizationId: 0, BranchId: 0, RoleId: 0});
                    this.props.changeSelectedParams({roleId: 0}, true);
                    break;
                default:
                    writeDebug(`undefined clicked type: ${type} and id: ${id} and name: ${name}`);
                    break;
            }            
        });                
    }

    handleDoubleClickItem(type, id) {
        if(this.props.onDoubleClickItem !== undefined) {
            this.props.onDoubleClickItem(type, id);
        }
    }

    handleRightClickItem = (event, type, id) => {
        let contextMenuId = null;
        if (type === "user") {
            contextMenuId = this.userContextMenu;
        } else {
            return;
        }

        if(contextMenuId !== null) {
            contextMenu.show({
                id: contextMenuId,
                event: event,
                props: {
                  id: id
                }
            });
        }        
    }

    handleCollapse(name, collapse) {
        this._scrollBarRef.updateScroll();        
    }

    handleChangeSearchText(value) {
        if (this.searchTimeout !== null) {            
            clearTimeout(this.searchTimeout)
            this.searchTimeout = null
        } 

        this.setState({searchText: value.trimStart()}, () => {
            this.searchTimeout = setTimeout(() => {
                this.props.filterLeftNavBarData(this.state.searchText);
          
                clearTimeout(this.searchTimeout)
                this.searchTimeout = null
            }, 100); 
        });
    }

    resetSearchBar() {
        if (this.searchTimeout !== null) {            
            clearTimeout(this.searchTimeout)
            this.searchTimeout = null
        }

        this.props.filterLeftNavBarData("");
        this.setState({searchText: "", loading: false});
    }

    renderOrganizations(){
        if(this.props.leftNavBarData.organizations === null) {
            return null;
        }

        if(this.props.settings.useBranch) {
            let organizationsKeys = Object.keys(this.props.leftNavBarData.organizations);
            if(organizationsKeys.length <= 0){
                return null;
            } else if(organizationsKeys.length === 1){    
                let id = organizationsKeys[0];
                let organization = this.props.leftNavBarData.organizations[id];  
                
                let branchList = {};        
                for (let [id, object] of Object.entries(organization["branches"])) {
                    branchList[object.name] = { id: id};
                }

                return <CollapseList collapsed={false} onCollapse={this.handleCollapse} header={this.props.translate("NavBar.Branches")} list={branchList} itemIcon="fa fa-home"
                        type="branch" levelType="organization" headerId={parseInt(id)} clickItem={this.handleClickItem} doubleClickItem={this.handleDoubleClickItem} 
                        selectedItemType={this.state.selectedItemType} selectedItemId={this.state.selectedItemId} ordered={true}/>
            } else {
                let organizations = {};
                for (let [id, organization] of Object.entries(this.props.leftNavBarData.organizations)) {
                    let branchList = {};        
                    for (let [id, object] of Object.entries(organization["branches"])) {
                        branchList[object.name] = { id: id};
                    }

                    organizations[organization["name"]] = <CollapseList onCollapse={this.handleCollapse} key={organization["name"]} header={organization["name"]} list={branchList} 
                            headerIcon="fa fa-cloud" itemIcon="fa fa-home" type="branch" levelType="organization" headerId={parseInt(id)} clickItem={this.handleClickItem} doubleClickItem={this.handleDoubleClickItem}
                            selectedItemType={this.state.selectedItemType} selectedItemId={this.state.selectedItemId} ordered={true}/>; 
                }

                return (<CollapseList collapsed={false} onCollapse={this.handleCollapse} clickItem={this.handleClickItem} type="organization" levelType="organizations" headerId={0} 
                header={this.props.translate("NavBar.Organizations")} ordered={true}>
                    {organizations}
                </CollapseList>);
            }
        } else if (this.props.settings.useOrganization) {
            let organizationsKeys = Object.keys(this.props.leftNavBarData.organizations);
            if(organizationsKeys.length <= 1){
                return null;
            } else { 
                let organizationList = {};        
                for (let [id, object] of Object.entries(this.props.leftNavBarData.organizations)) {
                    organizationList[object.name] = { id: id};
                }
                return <CollapseList collapsed={false} onCollapse={this.handleCollapse} header={this.props.translate("NavBar.Organizations")} list={organizationList} itemIcon="fa fa-home"
                        type="organization" levelType="organizations" headerId={0} clickItem={this.handleClickItem} doubleClickItem={this.handleDoubleClickItem} ordered={true}
                        selectedItemType={this.state.selectedItemType} selectedItemId={this.state.selectedItemId}/>
            }
        }
    }

    renderUsers() {
        let usersList = this.getUserListForSort(this.props.leftNavBarData.users);
        return <CollapseList onCollapse={this.handleCollapse} rightClickItem={this.handleRightClickItem} clickItem={this.handleClickItem} doubleClickItem={this.handleDoubleClickItem} list={usersList} 
                itemIcon="fa fa-user-circle" ordered={true} type="user" levelType="users" headerId={0} header={this.props.translate("NavBar.Users")}/>; 
    }

    getUserListForSort(data){
        let list = {};
        let nameList = {};
        for (let [id, object] of Object.entries(data)) {
            if(!nameList[object.name]) {
                nameList[object.name] = object.name;
                list[object.name] = { id: id, userName: object.userName};
            } else {
                if(list[object.name]){
                    let oldUser = list[object.name];
                    list[`${object.name} (${oldUser.userName})`] = { id: oldUser.id, userName: oldUser.userName};
                    delete list[object.name];
                }
                list[`${object.name} (${object.userName})`] = { id: id, userName: object.userName};
            }            
        }
        return list;
    }

    renderRoles(){
        if(this.props.leftNavBarData.roles === null) {
            return null;
        }

        let roles = {};
        for (let [id, role] of Object.entries(this.props.leftNavBarData.roles)) {
            let usersList = this.getUserListForSort(role["users"]);
            roles[role.name] = <CollapseList onCollapse={this.handleCollapse} key={role["name"]} header={role["name"]} list={usersList} 
                    headerIcon="fa fa-users" itemIcon="fa fa-user-circle" type="user" levelType="role" headerId={parseInt(id)} clickItem={this.handleClickItem} doubleClickItem={this.handleDoubleClickItem}
                    selectedItemType={this.state.selectedItemType} selectedItemId={this.state.selectedItemId} ordered={true} rightClickItem={this.handleRightClickItem}/>;
        }

        return (<CollapseList collapsed={false} onCollapse={this.handleCollapse} rightClickItem={this.handleRightClickItem} clickItem={this.handleClickItem} type="role" levelType="roles" 
                    headerId={0} header={this.props.translate("NavBar.Roles")} ordered={true}>
            {roles}
        </CollapseList>);               
    }

    renderSearchBar() {
        return (
            <div className={`field ${this.state.searchText.length > 0 ? "has-addons" : ""}`}>
                <p className="control has-icons-left">
                    <input className="input" type="text" placeholder={this.props.translate("Search.People")} value={this.state.searchText} onChange={(e) => this.handleChangeSearchText(e.target.value)}/>
                    <span className="icon is-small is-left">
                        <i className="fa fa-search"></i>
                    </span>                    
                </p>
                {this.state.searchText.length > 0 ? 
                    <p className="control">
                        <span className="button reset-button" onClick={() => this.resetSearchBar()}>
                            <i className="fa fa-times"></i>
                        </span>                        
                    </p>
                : null}
            </div>
        );
    } 

    render() {
        appManager.init(this.props);
        return (
            <div className="left-navbar">
                <div className="left-navbar-search">
                    { this.renderSearchBar() }
                </div>
                <div className="left-navbar-tree">
                    {(this.props.leftNavBarData.organizations == null && this.props.settings.useBranch) || this.props.leftNavBarData.roles == null || this.state.loading ? 
                        <div className="loading-container">
                            { this.props.loading }
                        </div>                        
                    :
                        <>
                            <PerfectScrollbar className="left-navbar-scrollbar" ref={(ref) => this._scrollBarRef = ref} options={{ wheelSpeed: 0.6, suppressScrollX: true }}>
                                {(this.props.leftNavBarData.roles === [] || Object.keys(this.props.leftNavBarData.roles).length === 0) && this.state.searchText.length > 0 ? 
                                    <div>
                                        <p className="center-text">{this.props.translate("Search.NoData")}</p>
                                    </div>
                                : 
                                    <div>
                                        {this.renderOrganizations()}                            
                                        {this.renderUsers()}
                                        {this.renderRoles()}
                                    </div>
                                }
                                                                            
                            </PerfectScrollbar>
                            {appManager.getUserContextMenu(this.userContextMenu)}
                        </>
                    }
                    
                </div>
            </div>
        );
    }
}

LeftNavBar.propTypes = {
    onClickItem: PropTypes.func,
    onDoubleClickItem: PropTypes.func
};

export default withLocalize(connect(
    state => state.dashboardContext,
    dispatch => bindActionCreators(Object.assign({}, userContextActions, dashboardContextActions), dispatch)
)(LeftNavBar));