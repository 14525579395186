import Cookies from 'universal-cookie';
import { writeDebug } from '../components/common/logger';
import { push } from 'react-router-redux'
import axios from 'axios';
import CryptoJS from 'crypto-js';

import Moment from 'moment'
import ReactMoment from 'react-moment';
import { momentLocaleMap } from '../components/common/helper';

const cookies = new Cookies();
const langMap = { ".sk": "sk-SK", ".cz": "cs-CZ", ".en": "en-US", ".de": "de-DE" };
let requestMap = [];

const getLanguage = () => {
    let auth = localStorage.getItem('auth')
    let lang = cookies.get('Cribis_Culture');
    if (auth !== null) {
        let profile = JSON.parse(auth).profile;
        if (!lang || lang !== profile.agentLanguage) {
            setLanguage(profile.agentLanguage);
            return profile.agentLanguage;
        }
    }

    if (lang) {
        return lang;
    }
    lang = langMap[(window.location.hostname.match(/(.cz$)|(.sk$)|(.en$)|(.de$)/gi) || ['.en'])] || "en-US";//sk-SK en-US
    setLanguage(lang);
    return lang;
};

const setLanguage = (lang) => {
    cookies.set('Cribis_Culture', lang);

    let momentLang = momentLocaleMap[lang] || 'en-gb';
    Moment.locale(momentLang);
    ReactMoment.globalLocale = momentLang;
};

const getAuthorization = (bearer) => {
    if (localStorage === undefined || localStorage === null) {
        return "";
    }
    let auth = localStorage.getItem('auth');
    if (auth === null || auth === undefined) {
        return "";
    }
    auth = JSON.parse(auth);
    if (auth === null || auth === undefined || auth.token === null || auth.token === undefined) {
        return "";
    }

    return (bearer ? "Bearer " : "") + auth.token;
}

const createHeaders = () => {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': getLanguage(),
        'Authorization': getAuthorization(true)
    };
};

const apiGet = (url, params, onSuccess, onFail, always) => { return apiFetch(url, params, null, 'GET', onSuccess, onFail, always) };
const apiPost = (url, params, data, onSuccess, onFail, always) => { return apiFetch(url, params, data, 'POST', onSuccess, onFail, always) };
const apiPut = (url, params, data, onSuccess, onFail, always) => { return apiFetch(url, params, data, 'PUT', onSuccess, onFail, always) };
const apiDelete = (url, params, onSuccess, onFail, always) => { return apiFetch(url, params, null, 'DELETE', onSuccess, onFail, always) };

const apiFileDownload = (query, params, callback) => {
    let url = createHttpUrl(query, params);
    if ((url != null) && (url.length != 0)) {
        let win = window.open(url, '_blank');
        win.addEventListener("beforeunload", function (event) {
            event.preventDefault();
            if (isFunction(callback)) {
                callback();
            }
        });

    }
};


const fileType = {
    excel: {
        xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        csv: "text/csv"
    }
};

const createHttpUrl = (url, params) => {
    url = window.hostApiUrl + url;

    params["t"] = Date.now();
    params["lang"] = getLanguage();
    params["access_token"] = getAuthorization(false);

    var esc = encodeURIComponent;
    var query = Object.keys(params)
        .map(function (k) { return esc(k) + '=' + esc(params[k]); })
        .join('&');

    return url + "?" + query
}

const apiConfig = (url, params, data, method) => {
    let config = { method: method, headers: createHeaders(), cache: "no-cache", url, paramsSerializer: createQs };
    if (data !== null && data !== undefined) {
        config.data = JSON.stringify(data);
    }

    const t = Date.now();
    requestMap[url] = t;
    if (method !== 'POST') {
        config.params = Object.assign({}, params, { t });
    } else {
        config.params = params;
    }

    return config;
}

const apiFetch = async (url, params, data, method, onSuccess, onFail, always) => {
    writeDebug("fetch - " + method + " - " + url);
    let config = apiConfig(url, params, data, method);

    return axios.request(config)
        .then(res => {
            const tt = requestMap[url];
            const ttt = parseInt(res.headers['x-timestamp'], 10) || Number.MAX_SAFE_INTEGER || 9007199254740991; // Last number is for IE11. In IE11 is not defined Number.MAX_SAFE_INTEGER
            if (ttt >= tt && isFunction(onSuccess)) {
                onSuccess(res.data, res.headers);
            }
        })
        .catch(err => {
            if (isFunction(onFail)) {
                onFail(err.response);
            }
        })
        .then(() => {
            if (isFunction(always)) {
                always();
            }
        });
};

const isFunction = function (obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply);
}

const parseQuery = function (url) {
    let params = {};
    const parts = url.split(/[?]/);
    if (parts.length !== 2) {
        return params;
    }

    let arr = parts[1].split(/&|=/);
    for (let i = 0; i < arr.length; i += 2) {
        params[arr[i]] = arr[i + 1]
    }
    return params
};

const createQs = function (obj) {
    const keys = Object.keys(obj);
    return keys.map(key => {
        const value = obj[key];

        if (value === undefined || value === null) {
            return '';
        }

        return encodeURI(key) + '=' + encodeURI(value);
    }).filter(x => x.length > 0).join('&');
}

const setupInterceptors = (store, history) => {
    const interceptor = axios.interceptors.response.use(response => response, error => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        if (error.response.status === 401 && Date.parse(auth.expires) <= Date.now()) {
            axios.interceptors.response.eject(interceptor);
            return axios.post(getHostApi() + '/api/account/login',
                { grant_type: 'refresh_token', refresh_token: auth.refreshToken },
                { headers: createHeaders(), cache: "no-cache" })
                .then(respo => {
                    if (respo.data.data.success) {
                        store.dispatch({ type: 'USER_LOGIN', data: respo.data.data });
                        error.response.config.headers['Authorization'] = getAuthorization(true);
                        return axios.config(error.response.config);
                    } else {
                        store.dispatch({ type: 'LOGIN_EXPIRED' });
                        store.dispatch(push(`/login?returnUrl=${encodeURIComponent(window.location.pathname)}`));
                        return Promise.reject(error);
                    }
                }).catch(error => {
                    store.dispatch(push(`/login?returnUrl=${encodeURIComponent(window.location.pathname)}`));
                    return Promise.reject(error);
                }).finally(() => setupInterceptors(store, history));

        } else if (error.response.status === 403) {

        } else if (error.response.status === 404) {

        }

        return Promise.reject(error);
    });
};

const isObjectPathDefined = function (object, path) {
    if (object === null || object === undefined) {
        return false;
    }
    let o = object
    const p = path.split(/[.]/);
    for (let i = 0; i < p.length; i++) {
        if (o === null || o === undefined || !o.hasOwnProperty(p[i])) {
            return false;
        }
        o = o[p[i]];
    }
    return true;
}

const getHostUrl = function () {
    return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
}

const getHostApi = function () {
    return window.hostApiUrl;
}

const hasPermission = function (user, permission) {
    if (isObjectPathDefined(user, "userProfile.permissions") && user.userProfile.permissions.length) {
        const phash = CryptoJS.SHA1(permission.toLowerCase()).toString().toUpperCase();
        if (user.userProfile.permissions.indexOf(phash) !== -1) {
            return true;
        }
    }
    return false;
}

const checkSuccessResponse = function (response) {
    return response["error"] === null && (response.data === null || !response.data.hasOwnProperty("success") || response.data.success)
}

const getErrorMessage = function (response) {
    if (!response) {
        return "error";
    }
    if (response.hasOwnProperty("statusText")) {
        return response.statusText;
    }

    let message = "";
    if (response.hasOwnProperty("error") && response.error !== null && response.error.hasOwnProperty("message")) {
        message = response.error.message;
    }
    if (response.hasOwnProperty("data") && response.data !== null && response.data.hasOwnProperty("message")) {
        message += (message.length === 0 ? "" : " ") + response.data.message;
    }
    if (response.hasOwnProperty("data") && response.data !== null && response.data.hasOwnProperty("errorMessage")) {
        message += (message.length === 0 ? "" : " ") + response.data.errorMessage;
    }
    return message;
}

export { apiGet, apiPost, apiPut, apiDelete, apiFileDownload, apiConfig, createHttpUrl, fileType, getLanguage, setLanguage, parseQuery, createQs, setupInterceptors, isObjectPathDefined, getAuthorization, getHostUrl, getHostApi, hasPermission, checkSuccessResponse, getErrorMessage };