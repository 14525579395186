import { parseQuery } from '../../store/apiWrapper'
import moment from 'moment';

function writeDebug(message, data) { write('debug', message, data); }
function writeInfo(message, data) { write('info', message, data); }
function writeWarn(message, data) { write('warn', message, data); }
function writeErr(message, data) { write('err', message, data); }

const lvlMap = {
    'debug':4,
    'info':3,
    'warn':2,
    'err':1
};
const methodMap = {
    'debug': console.log,
    'info': console.info,
    'warn': console.warn,
    'err': console.error
};

function write(level, message, data){
    try {
        if (message === null || message === undefined || message === '') {
            if (isAllowed(level)) mapMethod(level)(moment().format('YYYY-MM-DD HH:mm:ss.SSS') + ' - [' + level + ']', data);
        } else if (data === null || data === undefined) {
            if (isAllowed(level)) mapMethod(level)(moment().format('YYYY-MM-DD HH:mm:ss.SSS') + ' - [' + level + '] - ' + message);
        } else {
            if (isAllowed(level)) mapMethod(level)(moment().format('YYYY-MM-DD HH:mm:ss.SSS') + ' - [' + level + '] - ' + message, data);
        }

    } catch(e){}
}

function isAllowed(requested) {
    const l1 = lvlMap[parseQuery(window.location.search).logLevel] || lvlMap[localStorage.getItem('logLevel')] || 0;
    const l2 = lvlMap[requested] || 0;

    return l1 >= l2;
}

function mapMethod(requested) {
    return methodMap[requested] || function(){};
}

export { writeDebug, writeInfo, writeWarn, writeErr };
