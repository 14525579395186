import React from 'react';
import { withLocalize } from "react-localize-redux";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { reportContextActions } from '../../../store/reportStore';
import { userContextActions } from '../../../store/userContextStore';
import FilterTableComponent from '../../common/filterTable/FilterTableComponent';
import Loading from '../../common/loading/Loading';
import { TableUrl } from '../../common/urlHelper';
import { app } from '../../common/appHelper';
import { writeErr } from '../../common/logger';

class NeosReports extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        if (this.props.dashboardContext.settings.appId !== app.NEOS) {
            writeErr(`App with Id ${this.props.dashboardContext.settings.appId} have not access right to cribis report.`);
            this.props.redirectToPage("/");
        }
    };

    render() {
        let tableUrl = new TableUrl(this.props.dashboardContext.settings.appId);

        let filter = {

        };

        const loading = <Loading></Loading>;
        return <>
            <div className="section">
                {/* <h2 className="section-title subtitle is-4">Test Header</h2> */}
                <FilterTableComponent filter={filter} tableUrl={tableUrl} type={"neosreports"} params={this.props.reportContext.settings.params} loading={loading} exportCsv={true}></FilterTableComponent>
            </div>
        </>;
    }
}

export default withLocalize(connect(
    state => ({ reportContext: state.reportContext, dashboardContext: state.dashboardContext }),
    dispatch => bindActionCreators(Object.assign({}, userContextActions, reportContextActions), dispatch)
)(NeosReports));