import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { dashboardContextActions } from '../../../store/dashboardStore';

import MultiCribisPage from '../multiCribis/MultiCribisPage';
import NeosPage from '../neos/NeosPage';
import IRatingPage from '../iRating/IRatingPage';

import Logo from '../../common/logo';
import { app } from '../../common/appHelper';
import QuickView from '../../common/quickView/QuickView';
import ModalWindow from '../../common/modalWindow/ModalWindow';

// style
import './HomePage.scss';

var closeInterval;

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoader: this.props.settings.loadingPage
        };
    }

    componentDidMount() {
        if(this.props.settings.loadingPage) {
            this.setState({pageLoader: true}, () => {
                this.closePageLoader();
                this.props.updateSettings({loadingPage: false});
            });
        }        
    }

    componentWillUnmount(){
        clearTimeout(closeInterval);
    }

    componentDidUpdate(prevProps) {
        if(this.props.selectApp !== prevProps.selectApp)
        {
            this.setState({pageLoader: true}, () => {
                this.closePageLoader();
                this.props.updateSettings({loadingPage: false});
            })
        }
    }     

    closePageLoader(){
        if(this.state.pageLoader) {
            closeInterval = setTimeout(() => {this.setState({pageLoader: false})}, 2000); 
        }           
    }

    render() {
        var selectApp = this.props.selectApp !== undefined && this.props.selectApp !== null ? this.props.selectApp : null;  
        if(selectApp !== undefined && selectApp !== null) {
            let appElement = <div>Undefined :(</div>
            if(selectApp.key === app.MultiCribis) {
                // MultiCribis app
                appElement = <MultiCribisPage appId={selectApp.key}></MultiCribisPage>;
            } else if(selectApp.key === app.NEOS) {
                // NEOS app
                appElement = <NeosPage appId={selectApp.key}></NeosPage>;
            } else if(selectApp.key === app.IRating){
                // iRating app
                appElement = <IRatingPage appId={selectApp.key}></IRatingPage>;
            }
            return (<React.Fragment>
                        <div className={`pageloader ${this.state.pageLoader ? "is-active" : ""}`}><span className="title">
                            <Logo idKey={selectApp.key} alt={`logo ${selectApp.app}`} class={"page-loader-logo"}></Logo></span>
                        </div>
                        {appElement}
                        <QuickView show={this.props.quickView.show} showHeader={false} onClose={this.props.hideQuickView}>{this.props.quickView.body}</QuickView>
                        <ModalWindow action={this.props.modalWindow.action} title={this.props.modalWindow.title} show={this.props.modalWindow.show} 
                                    type={this.props.modalWindow.type} onClose={this.props.hideModalWindow}>
                            {this.props.modalWindow.body}
                        </ModalWindow>
                    </React.Fragment>                
            )            
        }     
        
        return (<div>Undefined :(</div>)
    }
}

export default connect(
    state => state.dashboardContext,
    dispatch => bindActionCreators(dashboardContextActions, dispatch)
)(HomePage);