import React from 'react';

import "./Loading.scss";

class Loading extends React.Component {
    render(){
        return (<div className={`lds-ripple ${this.props.className ? this.props.className : ""}`}><div></div><div></div></div>);
    }
}

export default Loading;