import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withLocalize } from "react-localize-redux";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Accordion from '../../common/accordion/Accordion';
import { dashboardContextActions } from '../../../store/dashboardStore';
import SelectComponent from '../../common/select/SelectComponent';

class BranchForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            branch: Object.assign({}, this.props.branch)
        }
    }    

    render() {
        let branch = this.state.branch;
        if(branch.created !== undefined) {
            branch.created = <React.Fragment><Moment format="L">{branch.created}</Moment><Moment format="LT">{branch.created}</Moment></React.Fragment>
        }
        if(branch.modified !== undefined && branch.modified !== null) {
            branch.modified = <React.Fragment><Moment format="L">{branch.modified}</Moment><Moment format="LT">{branch.modified}</Moment></React.Fragment>
        } 
        let organizationsList = null;
        if(branch.id === 0) {
            organizationsList = Object.entries(this.props.leftNavBarData.organizations).map((e) => ( { value: e[0], label: e[1]["name"] } ));
            if(organizationsList.length === 1) {
                branch.organizationId = parseInt(organizationsList[0].value);
            }
        }
        

        return (
                <Formik
                    initialValues={branch}
                    validate={values => {
                        let errors = {};
                        if(!values.name){
                            errors.name = this.props.translate('Common.RequiredField');
                        }

                        if (!values.email) {
                            errors.email = this.props.translate('Common.RequiredField');
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = this.props.translate('Common.InvalidEmail');
                        }

                        if(!values.address){
                            errors.address = this.props.translate('Common.RequiredField');
                        }

                        if(!values.organizationId || values.organizationId === 0){
                            errors.organizationId = this.props.translate('Common.RequiredField');
                        }
                    
                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting }) => {
                        let branch = {
                            id: values["id"],
                            name: values["name"],
                            email: values["email"],
                            active: values["active"],
                            address: values["address"],
                            organizationId: values["organizationId"]                  
                        };

                        if(branch.id === 0) {                          
                            this.props.createBranch(branch, setSubmitting);
                        } else if(branch.id > 0) {
                            this.props.updateBranch(branch, setSubmitting);
                        }
                    }}                    

                    render={({ errors, values, isSubmitting, setFieldValue, handleSubmit }) => (
                        <React.Fragment>
                            <Form className="my-form light">
                                <div className="header">
                                    <div className="buttons top-buttons">
                                        <button className="button is-white" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                                            <i className="fa fa-check"></i>
                                            OK
                                        </button>
                                        <button className="button is-white" type="button" onClick={this.props.hideQuickView} disabled={isSubmitting}>
                                            <i className="fa fa-times"></i>
                                            { this.props.translate('Common.Button.Cancel') }
                                        </button>
                                    </div>
                                    <h2 className="subtitle is-3"> 
                                        { values.id !== 0 ? this.props.translate('Branch.EditTitle', {0: values.name}) : this.props.translate('Branch.CreateTitle', {0: values.name}) }
                                    </h2>
                                </div>
                                <div className="form">
                                    <PerfectScrollbar ref={(ref) => this.scrollBarRef = ref} options={{ wheelSpeed: 0.6, suppressScrollX: true }}>
                                        <div className="form-content">                                            
                                            <Field type="hidden" name="id"></Field>
                                        
                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal">
                                                    <label className="label">{ this.props.translate('Common.FullName') }</label>
                                                </div>
                                                <div className="field-body">
                                                    <div className="field field-with-checkbox-80">                                    
                                                        <p className="control">
                                                            <Field type="text" name="name" className={`input ${errors.name ? "is-danger" : ""}`} />
                                                        </p>   
                                                        <ErrorMessage name="name" component="p" className="help is-danger" />                         
                                                    </div>

                                                    <div className="field center">                                      
                                                        <Field name="active" type="checkbox" checked={values.active} className={`is-checkradio is-primary`} id="checkbox-active" />
                                                        <label onClick={() =>setFieldValue("active", !values.active)} className="label" htmlFor="checkbox-active">{ this.props.translate('Common.Active') }</label>
                                                    </div>
                                                </div>                            
                                            </div>                        

                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal">
                                                    <label className="label">{ this.props.translate('Common.Email') }</label>
                                                </div>
                                                <div className="field-body">
                                                    <div className="field">
                                                        <p className="control">
                                                            <Field type="email" name="email" className={`input ${errors.email ? "is-danger" : ""}`} />
                                                        </p>   
                                                        <ErrorMessage name="email" component="p" className="help is-danger" />                         
                                                    </div>
                                                </div>                            
                                            </div>  

                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal">
                                                    <label className="label">{ this.props.translate('Common.Address') }</label>
                                                </div>
                                                <div className="field-body">
                                                    <div className="field">
                                                        <p className="control">
                                                            <Field type="text" name="address" className={`input ${errors.address ? "is-danger" : ""}`} />
                                                        </p>   
                                                        <ErrorMessage name="address" component="p" className="help is-danger" />                         
                                                    </div>
                                                </div>                            
                                            </div> 

                                            {values.id > 0 && 
                                                <Field type="hidden" name="organizationId"></Field>
                                            }
                                            {values.id === 0 && organizationsList.length > 1 ?                           
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">{ this.props.translate('Common.Organization') }</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <div className="control">
                                                                <SelectComponent options={organizationsList} className={`${errors.organizationId ? "is-danger" : ""}`}
                                                                    name="organizationId" value={[values.organizationId.toString()]} handleChange={(value) => setFieldValue("organizationId", parseInt(value[0]))}/>
                                                            </div>                      
                                                        </div>
                                                    </div>                            
                                                </div>
                                            :
                                                null
                                            }                                        
                                            {values.id > 0 && (values.created || values.createdBy || values.modified || values.modifiedBy) ?
                                                <div className="info">
                                                    <Accordion onToggled={(active) => this.scrollBarRef.updateScroll()} header={ this.props.translate('Branch.ShortAuditLog')} headerIcon="fa fa-info-circle"> 
                                                        {values.created ? 
                                                            <p>{ this.props.translate('Branch.Created', {date: values.created}) }</p>
                                                        : 
                                                            null
                                                        }
                                                        {values.createdBy ? 
                                                            <p>{ this.props.translate('Branch.CreatedBy', {user: values.createdBy}) }</p>
                                                        : 
                                                            null
                                                        }
                                                        {values.modified ? 
                                                            <p>{ this.props.translate('Branch.Edited', {date: values.modified}) }</p>
                                                        : 
                                                            null
                                                        }
                                                        {values.modifiedBy ? 
                                                            <p>{ this.props.translate('Branch.EditedBy', {user: values.modifiedBy}) }</p>
                                                        : 
                                                            null
                                                        }
                                                    </Accordion>
                                                </div>
                                            : 
                                                null
                                            } 
                                       </div> 
                                    </PerfectScrollbar> 
                                </div>   
                            </Form>                          
                        </React.Fragment>
                    )}
                />
        );
    }
}

BranchForm.propTypes = {
    branch: PropTypes.object.isRequired
};

export default withLocalize(connect(
    state => state.dashboardContext,
    dispatch => bindActionCreators(dashboardContextActions, dispatch)
)(BranchForm));