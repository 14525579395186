import React from 'react';
import { withLocalize } from "react-localize-redux";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { dashboardContextActions } from '../../../store/dashboardStore';

class ChangePasswordForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    render() {
        return (
            <Formik
                initialValues={{username: this.props.username, oldPassword: '', newPassword: '', newPasswordRepeat: ''}}
                validate={values => {
                    let errors = {};

                    if(!values.oldPassword){
                        errors.oldPassword = this.props.translate('Common.RequiredField');
                    }

                    if(!values.newPassword){
                        errors.newPassword = this.props.translate('Common.RequiredField');
                    } else if (values.newPassword.length < 6) {
                        errors.newPassword = this.props.translate('PwdCheck.LengthError');
                    }

                    if(!values.newPasswordRepeat){
                        errors.newPasswordRepeat = this.props.translate('Common.RequiredField');
                    } else if (values.newPasswordRepeat.length < 6) {
                        errors.newPasswordRepeat = this.props.translate('PwdCheck.LengthError');
                    }

                    if(values.newPassword && values.newPasswordRepeat && values.newPassword !== values.newPasswordRepeat){
                        errors.newPasswordRepeat = this.props.translate('PswdChange.PswdsDontMatch');
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.props.changePassword({username: values.username, oldPswd: values.oldPassword, newPswd: values.newPassword}, setSubmitting);
                }}   
                render={({ errors, touched, isSubmitting, handleSubmit }) => (
                    <Form className="my-form light">
                        <div className="header">
                            <div className="buttons top-buttons">
                                <button className="button is-white" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                                    <i className="fa fa-check"></i>
                                    OK
                                </button>
                                <button className="button is-white" type="button" onClick={this.props.hideQuickView} disabled={isSubmitting}>
                                    <i className="fa fa-times"></i>
                                    { this.props.translate('Common.Button.Cancel') }
                                </button>
                            </div>
                            <div className="display-flex">
                                <h2 className="subtitle is-3"> 
                                    {this.props.translate('DashboardLayout.ChangePassword')}
                                </h2>                                                                   
                            </div>
                        </div>
                        <div className="form">
                            <PerfectScrollbar ref={(ref) => this.scrollBarRef = ref} options={{ wheelSpeed: 0.6, suppressScrollX: true }}>
                                <div className="form-content">
                                    <Field type="hidden" name="username"></Field>

                                    <div className="field is-horizontal">
                                        <div className="field-label is-normal">
                                            <label className="label">{ this.props.translate('PswdChange.OldPassword') }</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <p className="control">
                                                    <Field type="password" name="oldPassword" className={`input ${touched.oldPassword && errors.oldPassword ? "is-danger" : ""}`} />
                                                </p>   
                                                <ErrorMessage name="oldPassword" component="p" className="help is-danger" />                         
                                            </div>
                                        </div>                            
                                    </div>

                                    <div className="field is-horizontal">
                                        <div className="field-label is-normal">
                                            <label className="label">{ this.props.translate('PswdChange.NewPassword') }</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <p className="control">
                                                    <Field type="password" name="newPassword" className={`input ${touched.newPassword && errors.newPassword ? "is-danger" : ""}`} />
                                                </p>   
                                                <ErrorMessage name="newPassword" component="p" className="help is-danger" />                         
                                            </div>
                                        </div>                            
                                    </div>

                                    <div className="field is-horizontal">
                                        <div className="field-label is-normal">
                                            <label className="label">{ this.props.translate('PswdChange.ConfirmPassword') }</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <p className="control">
                                                    <Field type="password" name="newPasswordRepeat" className={`input ${touched.newPasswordRepeat && errors.newPasswordRepeat ? "is-danger" : ""}`} />
                                                </p>   
                                                <ErrorMessage name="newPasswordRepeat" component="p" className="help is-danger" />                         
                                            </div>
                                        </div>                            
                                    </div> 
                                </div> 
                            </PerfectScrollbar>
                        </div>
                    </Form>
                )} 
            />
        );
    }
}

ChangePasswordForm.propTypes = {
    username: PropTypes.string.isRequired
};

export default withLocalize(connect(
    state => state.dashboardContext,
    dispatch => bindActionCreators(dashboardContextActions, dispatch)
)(ChangePasswordForm));