import React from 'react';
import { Formik, Form, Field, isFunction } from 'formik';
import { Translate } from "react-localize-redux";
import { toast } from 'react-toastify';
import { updateObject } from './storeFunctions';
import { apiDelete, apiGet, apiPost, apiPut, getErrorMessage, checkSuccessResponse } from './apiWrapper';
import { writeErr, writeDebug, writeWarn } from '../components/common/logger';
import { url } from '../components/common/urlHelper';
import BranchForm from '../components/dashboard/forms/BranchForm';
import UserForm from '../components/dashboard/forms/UserForm';
import ChangePasswordForm from '../components/dashboard/forms/ChangePasswordForm';
import { Event, EventManager } from '../managers/EventManager';
import { userContextActions } from './userContextStore';

const settings = "SETTINGS";
const customerData = "CUSTOMER_DATA";
const quickView = "QUICKVIEW";
const modalWindow = "MODALWINDOW";
const bodyParams = "BODY_PARAMS";
const selectedParams = "SELECTED_PARAMS";
const leftNavBar = "LEFTNAVBAR";

const initialState = { 
    settings: { useBranch: false, useOrganization: false, appId: 0, loadingPage: true },
    customerData: { roles: null, users: null, organizations: null },
    quickView: {show: false, body: null}, 
    modalWindow: {show: false, type: undefined, title: null, body: null, action: null}, 
    bodyParams: {bodyType: "user", data: null, selectedId: 0}, 
    selectedParams: {}, // selectedParams {organizationId: int, branchId: int, branchName: string}
    leftNavBarData: {searchText: "", organizationsRaw: null, organizations: null, rolesRaw: null, roles: null}    
};

const showModalWindow = (type, title, body, action) => { return {type: modalWindow, data: { show: true, type: type, title: title, body: body, action: action }} };
const hideModalWindow = () => { return {type: modalWindow, data: { show: false, action: null }} };
const hideQuickView = () => { return {type: quickView, data: { show: false, body: null }} };

//action creator for redux
export const dashboardContextActions = {
    updateSettings: (params) => async (dispatch) => {
        dispatch({ type: settings, data: { ...params }});   
    },
    loadCustomerData: (callback) => async (dispatch, getState) => {
        apiGet(window.hostApiUrl + url.appCustomerData(getAppId()), undefined, res => {
            let users = res["data"]["users"] || [];
            let roles = res["data"]["roles"] || [];
            let organizations = res["data"]["organizations"] || [];

            users = Object.assign({}, ...users.map(({id, firstName, surname, userName, email}) => 
            ({[id]: {firstName, surname, userName, email}})));

            roles = Object.assign({}, ...roles.map(({id, name}) => 
            ({[id]: {name}})));

            organizations = Object.assign({}, ...organizations.map(({id, name, limitedPermissions, roles, branches}) => 
            ({[id]: {name, limitedPermissions, roles, branches}})));

            dispatch({ type: customerData, data: { roles: roles, users: users, organizations: organizations }});  
            if(callback !== undefined) {
                getState();
                callback();
            }
        }, err => {
            writeErr(err);
        });         
    },
    updateCustomerData: (params) => async (dispatch) => {
        dispatch({ type: customerData, data: { ...params }});   
    },

    showQuickView: (body) => async (dispatch, getState) => {
        dispatch({type: quickView, data: { show: true, body: body } });
    },
    hideQuickView: () => async (dispatch, getState) => {          
        dispatch(hideQuickView());
    },

    showModalWindow: (type, title, body, action) => async (dispatch, getState) => {
        dispatch(showModalWindow(type, title, body, action));
    },
    hideModalWindow: () => async (dispatch, getState) => {
        dispatch(hideModalWindow());
    },
    deleteModalWindow: (url, body, callback) => async (dispatch, getState) => {
        let action = 
            <Formik
                initialValues={{url: url}}
                onSubmit={(values, { setSubmitting }) => {                     
                    apiDelete(window.hostApiUrl + values.url, undefined, res => {
                        if(checkSuccessResponse(res)) {
                            let deletedRecord = undefined;
                            if(res.data !== null && res.data.hasOwnProperty("result") && res.data.result === "disabled") {
                                writeDebug(`Deactive success, url: ${values.url}`);
                                toast.success(<Translate id="Message.Success.DeactiveRecord">Success, record has been deactivated.</Translate>);
                                deletedRecord = false;
                            } else {
                                writeDebug(`Delete success, url: ${values.url}`);
                                toast.success(<Translate id="Message.Success.DeleteRecord">Success, record has been deleted.</Translate>);
                                deletedRecord = true;
                            }
                            
                            if(callback !== undefined) {
                                callback(deletedRecord);
                            }                            
                            dispatch(hideModalWindow());                            
                        } else {
                            let errorMessage = getErrorMessage(res);
                            writeWarn(`Delete failed: message: ${errorMessage}`);
                            toast.warn(errorMessage);
                            if(callback !== undefined) {
                                callback(false);
                            }   
                        }
                    }, err => {
                        writeErr(err);
                        if(callback !== undefined) {
                            callback(false);
                        }   
                    }, () => {
                        setSubmitting(false);
                    });                  
                }}
                render={({ isSubmitting, handleSubmit }) => (
                    <React.Fragment>
                        <Form>
                            <Field type="hidden" name="url"></Field> 
                            <button onClick={ handleSubmit } type="submit" className="button is-danger is-outlined" disabled={isSubmitting}>
                                <span><Translate id="Common.Button.Delete"></Translate></span>
                                <span className="icon is-small"><i className="fa fa-times"></i></span>
                            </button> 
                        </Form>
                    </React.Fragment>
                )}
            />;
        let title = <Translate id="Common.AreYouSure">Are you sure?</Translate>;
        
        dispatch(showModalWindow("Delete", title, body, action));
    },

    changeBodyParams: (params) => async (dispatch, getState) => {
        dispatch({ type: bodyParams, data: { data: {...params} }});              
    },
    selectBodyItem: (id, data) => async (dispatch, getState) => {
        dispatch({ type: bodyParams, data: { selectedId: id, optionalData: data }});
    },
    selectBodyType: (type) => async (dispatch, getState) => {
        dispatch({ type: bodyParams, data: { bodyType: type }});
    },
    changeSelectedParams: (params, overwride = false) => async (dispatch, getState) => {
        dispatch({ type: selectedParams, overwride: overwride, data: { ...params } });
    },
    resetParams: () => async (dispatch, getState) => {
        dispatch({ type: selectedParams, overwride: true, data: {} });
        dispatch({ type: bodyParams, data: initialState.bodyParams });
    },

    // LeftNavBar
    loadLeftNavBarData: () => async (dispatch, getState) => {
        apiGet(window.hostApiUrl + url.appInfoData(getAppId()), undefined, resInner => {
            let organizations = resInner["data"]["organizations"] || [];
            let roles = resInner["data"]["roles"] || [];

            if(getState().dashboardContext.settings.useBranch || getState().dashboardContext.settings.useOrganization) {
                organizations = Object.assign({}, ...organizations.map(({id, name, branches, users}) => 
                    ({[id]: {name, branches: Object.assign({}, ...branches.map(({id, name, users}) => ({[id]: {name, users}}))), 
                        users: users }})));
            } else {
                organizations = null;
            }

            let usersList = getState().dashboardContext.customerData.users;
            roles = Object.assign({}, ...roles.map(({id, name, users}) => 
                ({[id]: {name, users: Object.assign({}, ...users.map((id) => {
                    let user = usersList[id]; 
                    return {[id]: {name: `${user.firstName} ${user.surname}`, userName: user.userName}}} 
                ))}})));

            let users = {};        
            for (let [id, object] of Object.entries(usersList)) {
                users[Number.parseInt(id)] = { name: `${object.firstName} ${object.surname}`, userName: object.userName};
            }

            dispatch({type: leftNavBar, data: { organizationsRaw: organizations, organizations: copyObject(organizations), users: users, rolesRaw: roles, roles: copyObject(roles)}});
        }, err => {
            writeErr(err);
        });
    },
    filterLeftNavBarData: (searchText) => (dispatch, getState) => {
        let leftNavBarData = getState().dashboardContext.leftNavBarData;
        let users = getState().dashboardContext.customerData.users;
        if(!users) {
            writeErr("Can not filter left nav bar data, users is not loaded.")
            return;
        }

        if(searchText.length === 0) {
            let usersList = {};        
            for (let [id, object] of Object.entries(users)) {
                usersList[Number.parseInt(id)] = { name: `${object.firstName} ${object.surname}`, userName: object.userName};
            }

            dispatch({type: leftNavBar, data: {searchText: searchText, organizations: leftNavBarData.organizationsRaw, users: usersList, roles: leftNavBarData.rolesRaw}});
        } else {
            let filterUsersId = [];
            iterateObject(users, (id, user) => {
                let search = searchText.toLowerCase();
                if( `${user.firstName} ${user.surname}`.toLowerCase().includes(search) || 
                    `${user.surname} ${user.firstName}`.toLowerCase().includes(search) ||
                    user.userName.toLowerCase().includes(search) || 
                    user.email.toLowerCase().includes(search)) 
                {
                    filterUsersId.push(parseInt(id));
                }
            });

            if(filterUsersId.length === 0) {
                dispatch({type: leftNavBar, data: {searchText: searchText, organizations: [], users: [], roles: []}});
            } else {               
                // find in organization structure
                let organizations = null;
                if(getState().dashboardContext.settings.useBranch) {
                    organizations = copyObject(leftNavBarData.organizationsRaw);
                    iterateObject(organizations, (organizationId, organization) => {
                        iterateObject(organization.branches, (branchId, branch) => {
                            let findIds = branch.users.filter(id => filterUsersId.includes(id));
                            if(findIds.length === 0) {      
                                delete organization.branches[branchId];          
                            } else {
                                branch.users = findIds;
                            }
                        });
                        if(Object.keys(organization.branches).length === 0) {
                            delete organizations[organizationId];
                        }
                    });
                } else if (getState().dashboardContext.settings.useOrganization) {
                    organizations = copyObject(leftNavBarData.organizationsRaw);
                    iterateObject(organizations, (organizationId, organization) => {
                        organization.users = organization.users.filter(id => filterUsersId.includes(id));
                        if(organization.users.length === 0) {
                            delete organizations[organizationId];
                        }                        
                    });
                }

                // find in role structure
                let roles = copyObject(leftNavBarData.rolesRaw);
                iterateObject(roles, (roleId, role) => {
                    iterateObject(role.users, (userId, name) => {
                        if(!filterUsersId.includes(parseInt(userId))){
                            delete role.users[userId];
                        }
                    });
                    if(Object.keys(role.users).length === 0) {
                        delete roles[roleId];
                    }
                });

                let usersList = {};        
                for (let [id, object] of Object.entries(users)) {
                    if(filterUsersId.includes(Number.parseInt(id))) {
                        usersList[Number.parseInt(id)] = { name: `${object.firstName} ${object.surname}`, userName: object.userName};
                    }                    
                }

                dispatch({type: leftNavBar, data: {searchText: searchText, organizations: organizations, users: usersList, roles: roles}});
            }
        }
    },
    updateUserLeftNavBarData: (user) => async (dispatch, getState) => {
        let leftNavBarData = getState().dashboardContext.leftNavBarData;       
        let roles = getState().dashboardContext.customerData.roles;
        for (var keyId in user.roles) {
            if(leftNavBarData.rolesRaw.hasOwnProperty(user.roles[keyId])) {
                if(leftNavBarData.rolesRaw[user.roles[keyId]]["users"].hasOwnProperty(user.id)) {                    
                    leftNavBarData.rolesRaw[user.roles[keyId]]["users"][user.id]["name"] = `${user.name} ${user.surname}`; // update
                } else {                    
                    leftNavBarData.rolesRaw[user.roles[keyId]]["users"][user.id] = { name: `${user.name} ${user.surname}`, userName: user.userName }; // insert
                }
            } else {
                let users = {};
                users[user.id] = {name: `${user.name} ${user.surname}`, userName: user.userName};
                leftNavBarData.rolesRaw[user.roles[keyId]] = {name: roles[user.roles[keyId]].name, users: users };
            }
            if(leftNavBarData.roles.hasOwnProperty(user.roles[keyId])) {
                if(leftNavBarData.roles[user.roles[keyId]]["users"].hasOwnProperty(user.id)) {
                    leftNavBarData.roles[user.roles[keyId]]["users"][user.id]["name"] = `${user.name} ${user.surname}`;
                } else {
                    leftNavBarData.roles[user.roles[keyId]]["users"][user.id] = { name: `${user.name} ${user.surname}`, userName: user.userName };
                }                
            } else {
                let users = {};
                users[user.id] = {name: `${user.name} ${user.surname}`, userName: user.userName};
                leftNavBarData.roles[user.roles[keyId]] = {name: roles[user.roles[keyId]].name, users: users };
            }
        }

        let deletedRoles = user.oldRoles.filter(r => !user.roles.includes(r))
        for (keyId in deletedRoles) {
            if(leftNavBarData.rolesRaw.hasOwnProperty(deletedRoles[keyId])) {
                if(leftNavBarData.rolesRaw[deletedRoles[keyId]]["users"].hasOwnProperty(user.id)) {
                    delete leftNavBarData.rolesRaw[deletedRoles[keyId]]["users"][user.id];
                    if(Object.keys(leftNavBarData.rolesRaw[deletedRoles[keyId]]["users"]).length === 0) {
                        delete leftNavBarData.rolesRaw[deletedRoles[keyId]];
                    }
                }              
            }
            if(leftNavBarData.roles.hasOwnProperty(deletedRoles[keyId])) {
                if(leftNavBarData.roles[deletedRoles[keyId]]["users"].hasOwnProperty(user.id)) {
                    delete leftNavBarData.roles[deletedRoles[keyId]]["users"][user.id];
                    if(Object.keys(leftNavBarData.roles[deletedRoles[keyId]]["users"]).length === 0) {
                        delete leftNavBarData.roles[deletedRoles[keyId]];
                    }
                }               
            }
        }

        leftNavBarData.users[user.id] = { name: `${user.name} ${user.surname}`, userName: user.userName };

        dispatch({type: leftNavBar, data: leftNavBarData});
    }, 
    insertUserLeftNavBarData: (user) => async (dispatch, getState) => {
        let leftNavBarData = getState().dashboardContext.leftNavBarData;
        
        if(getState().dashboardContext.settings.useBranch) {
            if(leftNavBarData.organizationsRaw.hasOwnProperty(user.organizationId) && leftNavBarData.organizationsRaw[user.organizationId]["branches"].hasOwnProperty(user.branchId)) {
                leftNavBarData.organizationsRaw[user.organizationId]["branches"][user.branchId]["users"].push(user.id);
            }
            if(leftNavBarData.organizations.hasOwnProperty(user.organizationId) && leftNavBarData.organizations[user.organizationId]["branches"].hasOwnProperty(user.branchId)) {
                leftNavBarData.organizations[user.organizationId]["branches"][user.branchId]["users"].push(user.id);
            }
        }        

        let roles = getState().dashboardContext.customerData.roles;
        for (var keyId in user.roles) {
            if(leftNavBarData.rolesRaw.hasOwnProperty(user.roles[keyId])) {
                leftNavBarData.rolesRaw[user.roles[keyId]]["users"][user.id] = { name: `${user.name} ${user.surname}`, userName: user.userName };
            } else {
                let users = {};
                users[user.id] = {name: `${user.name} ${user.surname}`, userName: user.userName};
                leftNavBarData.rolesRaw[user.roles[keyId]] = {name: roles[user.roles[keyId]].name, users: users };
            }
            if(leftNavBarData.roles.hasOwnProperty(user.roles[keyId])) {
                leftNavBarData.roles[user.roles[keyId]]["users"][user.id] = { name: `${user.name} ${user.surname}`, userName: user.userName };
            } else {
                let users = {};
                users[user.id] = {name: `${user.name} ${user.surname}`, userName: user.userName};
                leftNavBarData.roles[user.roles[keyId]] = {name: roles[user.roles[keyId]].name, users: users };
            }
        }

        leftNavBarData.users[user.id] = { name: `${user.name} ${user.surname}`, userName: user.userName };

        dispatch({type: leftNavBar, data: leftNavBarData});
    },
    deleteUserLeftNavBarData: (user) => async (dispatch, getState) => {
        let leftNavBarData = getState().dashboardContext.leftNavBarData;

        if(getState().dashboardContext.settings.useBranch) {
            iterateObject(leftNavBarData.organizationsRaw, (organizationId, value) => {
                if(value["branches"].hasOwnProperty(user.branchId)){
                    value["branches"][user.branchId]["users"] = value["branches"][user.branchId]["users"].filter(userId => userId !== user.id);
                }
            });
            iterateObject(leftNavBarData.organizations, (organizationId, value) => {
                if(value["branches"].hasOwnProperty(user.branchId)){
                    value["branches"][user.branchId]["users"] = value["branches"][user.branchId]["users"].filter(userId => userId !== user.id);
                }
            });
        }

        for (var keyId in user.roles) {
            if(leftNavBarData.rolesRaw.hasOwnProperty(user.roles[keyId])) {
                delete leftNavBarData.rolesRaw[user.roles[keyId]]["users"][user.id]; 
                if(Object.keys(leftNavBarData.rolesRaw[user.roles[keyId]]["users"]).length === 0) {
                    delete leftNavBarData.rolesRaw[user.roles[keyId]];
                }
            }
            if(leftNavBarData.roles.hasOwnProperty(user.roles[keyId])) {
                delete leftNavBarData.roles[user.roles[keyId]]["users"][user.id]; 
                if(Object.keys(leftNavBarData.roles[user.roles[keyId]]["users"]).length === 0) {
                    delete leftNavBarData.roles[user.roles[keyId]];
                }
            }
        } 

        delete leftNavBarData.users[user.id];

        dispatch({type: leftNavBar, data: leftNavBarData});
    },
    // Branch
    showBranch: (id) => async (dispatch, getState) => {
        if(!getState().dashboardContext.settings.useBranch) {
            writeErr("Branch settings is disabled.");
            return;
        }
        if(getState().dashboardContext.quickView.show) {
            return;
        }

        if(id > 0) {
            apiGet(window.hostApiUrl + url.getBranch(getAppId(), id), undefined, res => {
                let branch = res["data"];
                let form = <BranchForm branch={branch}></BranchForm>;
                dispatch(dashboardContextActions.showQuickView(form));
            }, err => {
                writeErr(err);
            });  
        } else if (id === 0) {
            let branch = {
                id: 0,
                name: "",
                email: "",
                address: "",
                active: true,
                organizationId: getState().dashboardContext.selectedParams.organizationId || 0
            }            

            let form = <BranchForm branch={branch}></BranchForm>;
            dispatch(dashboardContextActions.showQuickView(form));
        }             
    },
    createBranch: (branch, setSubmitting) => async (dispatch, getState) => {
        if(!getState().dashboardContext.settings.useBranch) {
            writeErr("Branch settings is disabled.");
            return;
        }
        if(branch.organizationId === 0) {
            writeErr("Organization is not selected for create new branch.");
            toast.warn(<Translate id="Message.Warning.CreateBranchOrganizationEmpty"></Translate>);
            return;
        }
        let customerData = getState().dashboardContext.customerData;
        apiPost(window.hostApiUrl + url.addBranch(getAppId()), null, branch, res => {
            if(res["error"] === null) {
                writeDebug(`Branch ${branch.name} has been Created. New id: ${res["data"]["id"]}`);
                toast.success(<Translate id="Message.Success.CreateBranch"></Translate>);
                dispatch(hideQuickView());
  
                let leftNavBarData = getState().dashboardContext.leftNavBarData;
                if(leftNavBarData.organizationsRaw.hasOwnProperty(branch.organizationId)) {
                    leftNavBarData.organizationsRaw[branch.organizationId]["branches"][res["data"]["id"]] = { name: branch.name };
                } 
                if(leftNavBarData.organizations.hasOwnProperty(branch.organizationId)) {
                    leftNavBarData.organizations[branch.organizationId]["branches"][res["data"]["id"]] = { name: branch.name };
                }      
                
                if(customerData.organizations.hasOwnProperty(branch.organizationId)){
                    customerData.organizations[branch.organizationId].branches.push(res["data"]["id"]);
                }
                
                dispatch({type: leftNavBar, data: leftNavBarData});  
                dispatch(dashboardContextActions.updateCustomerData(customerData));

                EventManager.callGlobalEvent(Event.tableReloadBody);
            } else {
                let errorMessage = res["error"]["message"];
                writeWarn(errorMessage);
                toast.warn(errorMessage);
            }                                
        }, err => {                              
            writeErr(err);                                
        }, () =>{
            if(setSubmitting !== undefined) {
                setSubmitting(false);
            }
        });        
    },
    updateBranch: (branch, setSubmitting) => async (dispatch, getState) => {
        if(!getState().dashboardContext.settings.useBranch) {
            writeErr("Branch settings is disabled.");
            return;
        }
        apiPut(window.hostApiUrl + url.editBranch(getAppId(), branch.id), null, branch, res => {
            if(res["error"] === null) {
                writeDebug(`Branch ${branch.name} has been Edited. Id: ${branch.id}`);
                toast.success(<Translate id="Message.Success.EditBranch"></Translate>);
                dispatch(hideQuickView());
 
                let leftNavBarData = getState().dashboardContext.leftNavBarData;   
                if(leftNavBarData.organizationsRaw.hasOwnProperty(branch.organizationId) && leftNavBarData.organizationsRaw[branch.organizationId]["branches"].hasOwnProperty(branch.id)) {
                    leftNavBarData.organizationsRaw[branch.organizationId]["branches"][branch.id]["name"] = branch.name;
                }  
                if(leftNavBarData.organizations.hasOwnProperty(branch.organizationId) && leftNavBarData.organizations[branch.organizationId]["branches"].hasOwnProperty(branch.id)) {
                    leftNavBarData.organizations[branch.organizationId]["branches"][branch.id]["name"] = branch.name;
                }     
                
                dispatch({type: leftNavBar, data: leftNavBarData});    
                
                EventManager.callGlobalEvent(Event.tableReloadBody);
            } else {
                let errorMessage = res["error"]["message"];
                writeWarn(errorMessage);
                toast.warn(errorMessage);
            }                                
        }, err => {                                
            writeErr(err);                                
        }, () =>{
            if(setSubmitting !== undefined) {
                setSubmitting(false);
            }
        });        
    },
    deleteBranch: (id) => async (dispatch, getState) => {
        if(!getState().dashboardContext.settings.useBranch) {
            writeErr("Branch settings is disabled.");
            return;
        }

        let leftNavBarData = getState().dashboardContext.leftNavBarData; 
        let parentId = getState().dashboardContext.selectedParams.organizationId;
        if(!parentId) {
            parentId = getState().dashboardContext.bodyParams.optionalData.OrganizationId;
        }
        let name = leftNavBarData.organizations[parentId]["branches"][id]["name"];
        let body = <p><Translate id="Common.DeleteMessage" data={{name: name}}></Translate></p>

        let customerData = getState().dashboardContext.customerData; 
        dispatch(dashboardContextActions.deleteModalWindow(url.deleteBranch(getAppId(), id), body, (result) => {
            if(result) { 
                if(leftNavBarData.organizations.hasOwnProperty(parentId)){
                    delete leftNavBarData.organizations[parentId]["branches"][id];
                }

                dispatch({type: leftNavBar, data: leftNavBarData});
                
                if(customerData.organizations.hasOwnProperty(parentId)){
                    customerData.organizations[parentId].branches = customerData.organizations[parentId].branches.filter(value => value !== id);
                }
                dispatch(dashboardContextActions.updateCustomerData(customerData));

                EventManager.callGlobalEvent(Event.tableReloadBody);
            }                
        }));        
    }, 
    // User
    showUser: (id, myProfile, callback) => async (dispatch, getState) => {
        if(getState().dashboardContext.quickView.show) {
            return;
        }

        let settings = getState().dashboardContext.settings;
        let customerData = getState().dashboardContext.customerData;
        
        if(id > 0) {
            apiGet(window.hostApiUrl + url.getUser(getAppId(), id), undefined, res => {
                let user = res["data"];
                user.roles = user.roles.map((e) => ( e.toString())) || [];
                user.branchName = user.branchName || "";
                user.defaultLanguage = user.defaultLanguage !== undefined ? [user.defaultLanguage] : ["sk-SK"];                
                
                let branchId = settings.useBranch || settings.useOrganization ? user.branchId : undefined;                
                if(branchId !== undefined && branchId > 0) {
                    // filtered roles
                    iterateObject(customerData.organizations, (organizationId, organization) => {                        
                        if(organization.branches.includes(parseInt(branchId))) {
                            if(organization.limitedPermissions) {
                                user.rolesOptions = organization.roles.map((value) => ( {value: value.toString(), label: customerData.roles[value].name} ));
                            } else {
                                user.rolesOptions = Object.keys(customerData.roles).map((key) => ( {value: key.toString(), label: customerData.roles[key].name} ));
                            }                            
                            user.organizationName = organization.name;
                            return;
                        }                        
                    });
                } else {
                    // all roles
                    user.rolesOptions = Object.keys(customerData.roles).map((key) => ( {value: key.toString(), label: customerData.roles[key].name} ));
                }

                let form = <UserForm user={user} myProfile={myProfile} callback={callback}></UserForm>;
                dispatch(dashboardContextActions.showQuickView(form));          
            }, err => {
                writeErr(err);
            }); 
        } else if (id === 0) {
            let selectedParams = getState().dashboardContext.selectedParams;    
            let user = {
                id: 0,
                name: "",
                surname: "",
                userName: "",
                email: "",
                phone: "",
                description: "",
                active: true,
                roles: [],
                branchId: getState().dashboardContext.settings.useBranch ? selectedParams.branchId || 0 : undefined,
                organizationId: getState().dashboardContext.settings.useBranch ? selectedParams.organizationId || 0 : undefined,
                defaultLanguage: ["sk-SK"],
                branchList: null,
                organizationList: null
            }

            let selectedRoleId = selectedParams.roleId;
            if(selectedRoleId) {
                user.roles = [selectedRoleId.toString()]
            }
         
            if(settings.useBranch && user.id === 0) {
                var organizationId = 0;
                let branchMap = function(e) {
                    return { value: `${e[0]}_${organizationId}`, label: e[1]["name"] };
                }; 
                user.branchList = [];
                let organizations = getState().dashboardContext.leftNavBarData.organizationsRaw;
                for (organizationId in organizations) {
                    let branches = Object.entries(organizations[organizationId].branches).map(branchMap);
                    user.branchList = user.branchList.concat(branches);
                }       
    
                if(user.branchList.length === 1) {
                    let splitValue = user.branchList[0].value.split("_");
                    user.branchId = parseInt(splitValue[0]);
                    user.organizationId = parseInt(splitValue[1]);
                }
                // roles by organizationId
                user.rolesOptions = [];
                if(customerData.organizations.hasOwnProperty(user.organizationId)) {
                    let organization = customerData.organizations[user.organizationId];
                    if(organization.limitedPermissions) {
                        user.rolesOptions = organization.roles.map((roleId) => ( {value: roleId.toString(), label: customerData.roles[roleId].name} ));
                    } else {
                        user.rolesOptions = Object.keys(customerData.roles).map((key) => ( {value: key.toString(), label: customerData.roles[key].name} ));
                    }
                }
                // TODO fix add limited permision, later remove this commented code
                // user.rolesOptions = customerData.organizations.hasOwnProperty(user.organizationId) ? 
                //     customerData.organizations[user.organizationId].roles.map((roleId) => ( {value: roleId.toString(), label: customerData.roles[roleId].name} ))
                //     : [];
            } else if(settings.useOrganization && user.id === 0) {
                let organizations = customerData.organizations;
                const keys = Object.keys(organizations)
                if(keys.length <= 1) {
                    user.organizationId = parseInt(keys[0]);
                } else {
                    user.organizationList = [];
                    for (const key of keys) {
                        let value = organizations[key];
                        user.organizationList.push({ value: key, label: value["name"] });
                    }
                }
                // roles by organizationId
                user.rolesOptions = [];
                if(customerData.organizations.hasOwnProperty(user.organizationId)) {
                    let organization = customerData.organizations[user.organizationId];
                    if(organization.limitedPermissions) {
                        user.rolesOptions = organization.roles.map((roleId) => ( {value: roleId.toString(), label: customerData.roles[roleId].name} ));
                    } else {
                        user.rolesOptions = Object.keys(customerData.roles).map((key) => ( {value: key.toString(), label: customerData.roles[key].name} ));
                    }
                }
                // TODO fix add limited permision, later remove this commented code
                // user.rolesOptions = customerData.organizations.hasOwnProperty(user.organizationId) ? 
                //     customerData.organizations[user.organizationId].roles.map((roleId) => ( {value: roleId.toString(), label: customerData.roles[roleId].name} ))
                //     : [];
            } else {
                 // all roles
                 user.rolesOptions = Object.keys(customerData.roles).map((key) => ( {value: key.toString(), label: customerData.roles[key].name} ));
            }

            let form = <UserForm user={user}></UserForm>;
            dispatch(dashboardContextActions.showQuickView(form));
        }             
    },
    createUser: (user, setSubmitting, callback) => async (dispatch, getState) => {        
        if(getState().dashboardContext.settings.useBranch && user.branchId === 0) {
            writeErr("Branch is not selected for create new user.");
            toast.warn(<Translate id="Message.Warning.CreateUserBranchEmpty"></Translate>);
            return;
        } else if(getState().dashboardContext.settings.useOrganization && user.organizationId === 0) {
            writeErr("Organization is not selected for create new user.");
            toast.warn(<Translate id="Message.Warning.CreateUserOrganizationEmpty"></Translate>);
            return;
        }

        apiPost(window.hostApiUrl + url.addUser(getAppId()), null, user, res => {
            if(res["error"] === null) {
                user.id = res["data"]["id"];
                writeDebug(`User ${user.userName} has been Created. New id: ${user.id}, name: ${user.name} ${user.surname}`)
                toast.success(<Translate id="Message.Success.CreateUser"></Translate>);

                if(isFunction(callback)) {
                    callback(user);
                }
                dispatch(dashboardContextActions.hideQuickView());                 

                let users = getState().dashboardContext.customerData.users;
                users[user.id] = { firstName: user.name, surname: user.surname, userName: user.userName, email: user.email };
                dispatch(dashboardContextActions.updateCustomerData({users: users}));

                dispatch(dashboardContextActions.insertUserLeftNavBarData(user));

                EventManager.callGlobalEvent(Event.tableReloadBody);
            } else {
                let errorMessage = res["error"]["message"];
                writeWarn(errorMessage);
                toast.warn(errorMessage);
            }                                
        }, err => {                                
            writeErr(err);                                
        }, () =>{
            if(setSubmitting !== undefined) {
                setSubmitting(false);
            }            
        });
    },
    updateUser: (user, setSubmitting, callback) => async (dispatch, getState) => {
        apiPut(window.hostApiUrl + url.editUser(getAppId(), user.id), null, user, res => {
            if(res["error"] === null) {
                writeDebug(`User ${user.userName} has been Edited. Id: ${user.id}, name: ${user.name} ${user.surname}`);
                toast.success(<Translate id="Message.Success.EditUser"></Translate>);
                
                dispatch(dashboardContextActions.hideQuickView());

                let users = getState().dashboardContext.customerData.users;
                if(users.hasOwnProperty(user.id)){
                    users[user.id].firstName =  user.name;
                    users[user.id].surname =  user.surname;
                    users[user.id].email =  user.email;
                    dispatch(dashboardContextActions.updateCustomerData({users: users}));
                }

                dispatch(dashboardContextActions.updateUserLeftNavBarData(user));

                
                if(isFunction(callback)) {
                    callback(user);
                    EventManager.callGlobalEvent(Event.tableReload);
                } else {
                    EventManager.callGlobalEvent(Event.tableReloadBody);
                }
            } else {
                let errorMessage = res["error"]["message"];
                writeWarn(errorMessage);
                toast.error(errorMessage);
            }                                
        }, err => {                                
            writeErr(err);                                
        }, () =>{
            if(setSubmitting !== undefined) {
                setSubmitting(false);
            }            
        });
    },
    deleteUser: (id) => async (dispatch, getState) => {
        apiGet(window.hostApiUrl + url.getUser(getAppId(), id), undefined, res => {
            let user = res["data"];
            let name = `${user.name} ${user.surname}`;
            let body = <p><Translate id="Common.DeleteMessage" data={{name: name}}></Translate></p>
            dispatch(dashboardContextActions.deleteModalWindow(url.deleteUser(getAppId(), id), body, (result) => {
                if(result) {
                    let users = getState().dashboardContext.customerData.users;
                    delete users[user.id];
                    dispatch(dashboardContextActions.updateCustomerData({users: users}));
                    
                    dispatch(dashboardContextActions.deleteUserLeftNavBarData(user));

                    EventManager.callGlobalEvent(Event.tableReloadBody);
                }                
            }));
        }, err => {
            writeErr(err);
        });        
    },
    showChangePassword: (username) => async (dispatch, getState) => {
        if(getState().dashboardContext.quickView.show) {
            return;
        }

        let form = <ChangePasswordForm username={username}></ChangePasswordForm>;
        dispatch(dashboardContextActions.showQuickView(form));     
    },    
    changePassword: (data, setSubmitting) => async (dispatch, getState) => { 
        dispatch(userContextActions.changePassword(data, setSubmitting, () => {
            dispatch(dashboardContextActions.hideQuickView());
        }))
    },
};

function getAppId(){
    let selectApp = JSON.parse(localStorage.getItem("selectApp"));
    return selectApp ? selectApp.key : 0;
}

function copyObject(object) {
    return JSON.parse(JSON.stringify(object));
}

function iterateObject(object, functionCallback) {
    const keys = Object.keys(object)
    for (const key of keys) {
        let value = object[key];
        functionCallback(key, value);
    }
}

// store reducer
export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case settings:
            return updateObject(state, { settings: updateObject(state.settings, action.data) });
        case customerData:
            return updateObject(state, { customerData: updateObject(state.customerData, action.data) });
        case quickView:
            return updateObject(state, { quickView: updateObject(state.quickView, action.data) });
        case modalWindow:
            return updateObject(state, { modalWindow: updateObject(state.modalWindow, action.data) });
        case bodyParams:
            return updateObject(state, { bodyParams: updateObject(state.bodyParams, action.data) });
        case selectedParams:
            if(action.overwride) {
                return updateObject(state, { selectedParams: action.data });
            } else {
                return updateObject(state, { selectedParams: updateObject(state.selectedParams, action.data) });
            }            
        case leftNavBar:
            return updateObject(state, { leftNavBarData: updateObject(state.leftNavBarData, action.data) });
        default:
            return state;
    }
};