function updateObject(old, toUpdate) {
    return Object.assign({}, old, toUpdate);
}

function updateObjectProperty(old, propName, propValue) {
    const n = {...old};
    n[propName] = propValue;
    return n;
}

export { updateObject, updateObjectProperty }