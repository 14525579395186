import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withLocalize } from "react-localize-redux";

// style
import './ModalWindow.scss';

class ModalWindow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {              
        };

        this.handleClickClose = this.handleClickClose.bind(this);
        // this.handleChildClick = this.handleChildClick.bind(this);
    }

    handleClickClose(event) {
        if(event.isDefaultPrevented()) {
            return;
        }
        this.props.onClose();
        event.preventDefault();
    }

    getModalIcon() {
        if(this.props.type === "Info"){
            return <div className="display-flex"><img className="middle-container" alt="Info icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAz1BMVEUAAACAgIBVVapmZsxVgKpNe7VNebZOebRPebZPfLZOe7RNerVPe7ZQfbhRfrhTfblSfbZSfbhSfblRfrhSfbhSfblSfrlRfblRfrlSfrlRfbdRfbhRfrlRfbdSfbhQfLhRfLlRfbhbh8Jch8Jch8NciMNch8Fch8JciMJUgLtVgbtVgrtah8FbhsBbh8FPe7dPe7ZQe7Zyn9lOerVynthyn9hznthzn9l7qOF8p+F8qOF8qOJ8qeKBreaCruaCrueHsuuHs+yHtO2ItO6Lt/AJXV1vAAAAM3RSTlMAAgMFBk9QUlRUVVZXj5CRk6+vsLGysuXl5ebm5ufn6Ojo8fHx8fLy8vT09PT09Pf4+f6I4JpdAAABCUlEQVRYw+3Y1w6CMBSAYRBFxb0H7i0ucKEiKrbv/0zeGCONxIRT9cLzP8CXNk1biiBg3ygYL6iGoeZjQT5etN03z4TYZq8e5sAF0pMjvWdpKREMpucOfeRMk+D5Tp48Sh1NBq5Hc09dWS3YysRHlKmrgMCiyYKbHAisnVnQLoNAnbAg0TmD1wXnKZ9KIDC/ZMF1FgTGhiw4iIBASd25vUMDeOSENdfWu4xD0M2cmj6Jl1kCfNqISe2xnXfjhMjhRJSr3c2JEHvbqYYELklKpqLrlUxEwuvwt6088g/SlyHoZ1F4gR4jRRBBBBFEEEEEEUQQQQQ5f7T7flF5PCv8j/QfwTfhD4DPdQPVoTHlDWmhKQAAAABJRU5ErkJggg=="/></div>;
        } else if(this.props.type === "Save") {
            return <div className="display-flex"><img className="middle-container" alt="Save icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAYFBMVEUAAABNjMxHiMhHiMdHiMdOjcpPjspQjspRj8tTkMtemNBgmtBhmdBmoNdnoNdnoddxo9RypNRzpNR3qNl5q9p6q9qp0viq0/i23P7I4PXO5PjP5fjQ5fnR5vnf8P7///9xVRttAAAABHRSTlMAFM/QYg10YwAAANZJREFUWMPt1ssOwiAQheGqp95vVVurtvj+b+nChREYbkObGOffEr4NE6AopCwhrdkkM0iLgPoKAA7X1hnQb0nRBnq8FlC0aANbP0iLqSAphoOflfceQkwHCZEB2kUOqPqNKbJAm8gDLSITNEUuaIhsUBf5oCZmAFW3xjQrqDqAfzlou8YDS/8F22ARAZ4B7Bunt0MdAd6W/gdq9YgA1f1Surl5bXhOMCkBBfwXMPXjOSb4TEhAAYcBj2HzXAWDVRh4kkMRUOZQQJlDmUMBfwLM03CgxOoFeL/HzoS2kMsAAAAASUVORK5CYII="/></div>;
        } else if(this.props.type === "Delete") {
            return <div className="display-flex"><img className="middle-container" alt="Delete icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAS1BMVEUAAADMTU2/QEDJQ0PIQ0PJQkLGRUXIRkbJRUXKR0fJSEjJR0fKR0fIR0fKRkbJR0fYX1/YXl7aYGDUV1fVWFjrfHzsfX33jo73j4/op7aSAAAAFXRSTlMACgw9QUJDurzGx8jJzfT19fb2+Piq2hfAAAABZUlEQVRYw+3Y2XKDMAwFUEiIaZvEdIHW//+l2SkUbVd2n4IfmfgEBsaSblWt6+lW3QZsw/ZlI3r7r65BvCb2h1rw3t7Tdwfc4zYOP5/HjeQlRDx7KfHizQPEq8eLD88s3j1O/PWM4ujR4tQziROPEueeQZx5hNj2ab6G2Mjf3/BnQ99Kf6jdI/XzXeUXDR4kmjxANHpm0ewZRcAziZBnEEFPFWFPER2eKLo8QXR6rOj2GDHDow6nIRKXGqTcLrYvLmAFfPmA/uc1ibCniA5PFF2eIDo9VnR7jJjhkWKWR4OhqJclsi8lFPXcovhhh6KeS1QPh1DUg8XSByzhdTFDJOsR2OMa+he3yNZLpyjUX5co1nOHqPQHsKj2G+s8s84z/zjPlB/A69ePrHmGCB1mIjrPkLHIRETnGSa4GUV0nmGjpbuIzjNC+HUVgX5NCdNuItT/KXFf+UDyEplC3lls6zVofr51Aq36tue6pKMHAAAAAElFTkSuQmCC"/></div>;
        } else {
            return null;
        }
    }

    render() {
        let modalClass = this.props.type === "Delete" ? "modal-delete" : ""; // TODO added style for other type
        let modalIcon = this.getModalIcon();
        
        return (
            <div className={`modal is-active ${this.props.show ? "modal-active" : ""} ${modalClass}`}>
                <div onClick={(e) => this.handleClickClose(e)} className="modal-background"></div>
                <div className="modal-card">
                    <PerfectScrollbar ref={(ref) => this._scrollBarRef = ref} options={{ wheelSpeed: 0.6, suppressScrollX: true }}>
                        {this.props.title !== undefined ? 
                            <header className="modal-card-head">
                                {modalIcon}
                                <h2 className="modal-card-title">{this.props.title}</h2>
                            </header>
                        : null }
                        
                        <section className="modal-card-body">
                            {this.props.children}
                        </section>

                        <footer className="modal-card-foot level">
                            <div className="modal-buttons level-right">
                                <button onClick={(e) => this.handleClickClose(e)} className="button">{ this.props.translate("Common.Button.Cancel") }</button>
                                {this.props.type === "Delete" || this.props.type === "Save" ? 
                                    this.props.action
                                : null }  
                            </div>                              
                        </footer>
                    </PerfectScrollbar> 
                </div>
            </div>
        );
    }
}

ModalWindow.propTypes = {
    show: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['Info', 'Save', 'Delete']),
    //title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    action: PropTypes.element
};

export default withLocalize(ModalWindow);