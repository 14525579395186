import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from "react-localize-redux";
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import 'moment-timezone';
import 'rc-calendar/assets/index.css';
import 'rc-time-picker/assets/index.css';

const moment = require('moment');

class DateTimePickerField extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        defaultCalendarValue: PropTypes.object,
        handleChange: PropTypes.func.isRequired,
        format: PropTypes.string
    }
    
    constructor(props) {
        super(props);

        let formatDate = null;
        let formatTime = null;
        let format = null;
        let showTime = false;
        if(this.props.format === "date"){
            formatDate = moment().localeData().longDateFormat('L');
            format = formatDate;
        } else if(this.props.format === "datetime") {
            showTime = true;
            formatDate = moment().localeData().longDateFormat('L');
            formatTime = moment().localeData().longDateFormat('LT');
            format = `${formatDate} ${formatTime}`
        } else if(this.props.format === "datetimesecond") {
            showTime = true;
            formatDate = moment().localeData().longDateFormat('L');
            formatTime = moment().localeData().longDateFormat('LTS');
            format = `${formatDate} ${formatTime}`
        }

        this.state = {
            value: this.props.value,
            format: format || 'YYYY-MM-DD',
            formatDate: formatDate,
            formatTime: formatTime,
            open: false,
            showTime: showTime       
        };

        if(typeof this.state.value === 'string' || this.state.value instanceof String) {
            this.state.value = moment(this.state.value);
        }
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props
        if(oldProps.value !== newProps.value) {
            this.setState({value: newProps.value});            
        }
    }

    getTimePicker() {
        let showSecond = this.props.format === "datetimesecond";
        return <TimePickerPanel className={`${!showSecond ? "without-second" : ""}`} showSecond={showSecond} defaultValue={moment('00:00:00', this.state.formatTime)} />;
    }

    onChange = (value) => {
        this.setState({value: value}, () => {
            this.props.handleChange(this.state.value ? this.state.value : this.state.value);
        });
    }

    onOpenChange = (open) => {
        this.setState({open: open});
    }

    clear = (event) => {
        event.preventDefault();
        this.setState({value: undefined, open: false}, () => {
            this.props.handleChange(this.state.value);
        });        
    }

    disabledDate = (current) => {
        let date = moment(current).startOf('day');
        let minDate = this.props.minDate && moment(this.props.minDate).startOf('day');
        let maxDate = this.props.maxDate && moment(this.props.maxDate).startOf('day');
        let result = false;
        if(this.props.minDate && minDate > date) {
            result = true;
        }
        if(this.props.maxDate && maxDate < date) {
            result = true;
        }
        return result;
    }

    render() {
        const calendar = (<Calendar
            format = {this.state.format}
            disabledDate = {this.disabledDate}
            timePicker = {this.state.showTime ? this.getTimePicker() : null}
            className = "my-calendar"
          />);

        return <div tabIndex="0" className="date-picker-input input-with-reset-button">
                    <DatePicker 
                        animation="slide-up"
                        calendar={calendar} 
                        value={this.state.value}
                        onChange={this.onChange}
                        onOpenChange={this.onOpenChange}
                        open={this.state.open}
                    > 
                        {
                            ({ value }) => {
                            return (
                                
                                    <input readOnly tabIndex="-1" className="input filter-input" placeholder={this.props.label}
                                        value={(value && value.format(this.state.format)) || ''}
                                    />
                                
                            );
                            }
                        }
                    </DatePicker> 
                    <div className="reset-button" onClick={(e) => this.clear(e)}>
                        {/* <i className="fa fa-close"></i> */}
                    </div>
                </div>
                                       
    }
}

export default withLocalize(DateTimePickerField);