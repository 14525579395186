import { updateObject } from './storeFunctions';
//import { url } from '../components/common/urlHelper';

const settings = "REPORT_SETTINGS";

const initialState = { 
    settings: { type: null, params: null },
}

//action creator for redux
export const reportContextActions = {
    updateReportSettings: (params) => async (dispatch) => {
        dispatch({ type: settings, data: { ...params }});   
    },
}

// store reducer
export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case settings:
            return updateObject(state, { settings: updateObject(state.settings, action.data) });
        default:
            return state;
    }
};