import { writeDebug } from "../components/common/logger";

export const Event = {
    tableReload: "TABLE_RELOAD",
    tableReloadBody: "TABLE_RELOAD_BODY",
    hideContextMenu: "HIDE_CONTEXT_MENU"
};

var EventManagerData = {};

export const EventManager = {
    callGlobalEvent: function(eventId) {
        if(this.anyGlobalEvent(eventId)) {
            writeDebug(`Execute event: ${eventId}`);
            
            EventManagerData[eventId].forEach(func => {
                func();
            });
        }
    },
    addGlobalEvent: function(eventId, callback) {
        if(EventManagerData.hasOwnProperty(eventId)) {
            EventManagerData[eventId].push(callback);
        } else {
            EventManagerData[eventId] = [callback];
        }
    },
    removeGlobalEvent: function(eventId, callback) {
        if(EventManagerData.hasOwnProperty(eventId)) {
            EventManagerData[eventId] = EventManagerData[eventId].filter((value, index, arr) => value !== callback);

            if(EventManagerData[eventId].length) {
                delete EventManagerData[eventId];
            }
        }
    },
    deleteGlobalEvent: function(eventId) {
        if(EventManagerData.hasOwnProperty(eventId)) {
            delete EventManagerData[eventId];
        }
    },
    anyGlobalEvent: function(eventId) {
        return EventManagerData.hasOwnProperty(eventId) && EventManagerData[eventId].length > 0;
    },
    hasGlobalEvent: function(eventId, callback) {
        return EventManagerData.hasOwnProperty(eventId) && EventManagerData[eventId].includes(callback);
    }
};