import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { writeErr, writeDebug } from '../common/logger';
import { userContextActions } from '../../store/userContextStore';
import { dashboardContextActions } from '../../store/dashboardStore';
import Logo from '../common/logo';

// style
import './SelectAppPage.scss';

class SelectAppPage extends React.Component {

    constructor(props) {
        super(props);

        var selectApp = JSON.parse(localStorage.getItem("selectApp"));

        this.state = {
            appGroups: props.authentication.appIds,
            selectApp: selectApp
        };

        if(this.state.selectApp !== undefined && this.state.selectApp !== null){
            this.props.redirectToPage("/home");
            return;
        }

        var keys = Object.keys(this.state.appGroups);

        if(keys.length === 0) {
            writeErr("User has no assigned to any application.");
            this.props.logout();
            return;
        } else if(keys.length === 1) {
            this.selectApp(keys[0], this.state.appGroups[keys[0]]);
            return;
        }  
    };

    selectApp(key, app){
        var data = { key: key, app: app};
        localStorage.setItem('selectApp', JSON.stringify(data));
        this.props.updateSettings({appId: key, loadingPage: true});
        writeDebug(`Selected app: ${data.app},  id: ${data.key}`);
        this.props.redirectToPage("/");
    }

    handleClickSelectApp(key, app){
        this.selectApp(key, app);
    }

    renderAppLink = () => {
        var keys = Object.keys(this.state.appGroups);

        let apps = []
    
        // Outer loop to create parent
        for (let i = 0; i < keys.length; i++) {
            var data = { key: keys[i], app: this.state.appGroups[keys[i]]}
            apps.push(
            <div className="column" key={data.key}>
                <div className="app-button">
                    <div data-key={data.key} data-app={data.app} onClick={(e) => this.handleClickSelectApp(e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-app'))}>
                        <div className="middle-container">
                            <Logo idKey={keys[i]} alt={`logo ${this.state.appGroups[keys[i]]}`} class={"logo"}></Logo>
                        </div>  
                    </div>                  
                </div>                
            </div>
            );
        }
        return apps
    }

    render() {
        return (
            <div className="middle-container">
                <div className="columns">
                    {this.renderAppLink()}
                </div>
            </div>
        );
    }
}

export default connect(
    state => state.userContext,
    dispatch => bindActionCreators(Object.assign({}, userContextActions, dashboardContextActions), dispatch)
)(SelectAppPage);