import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from "react-localize-redux";
import DateTimePickerField from './DateTimePickerField';

const moment = require('moment');

class DateTimeRangeField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            valueFrom: this.props.valueFrom,
            valueTo: this.props.valueTo
        };

        if(typeof this.state.valueFrom === 'string' || this.state.valueFrom instanceof String) {
            this.state.valueFrom = moment(this.state.valueFrom);
        }
        if(typeof this.state.valueTo === 'string' || this.state.valueTo instanceof String) {
            this.state.valueTo = moment(this.state.valueTo);
        }
    };

    componentDidUpdate(oldProps) {
        const newProps = this.props
        if(oldProps.valueFrom !== newProps.valueFrom) {
            this.setState({valueFrom: newProps.valueFrom});            
        }
        if(oldProps.valueTo !== newProps.valueTo) {
            this.setState({valueTo: newProps.valueTo});            
        }
    }

    onChange = (type, value) => {
        if(type === "From") {
            this.setState({valueFrom: value}, () => {
                this.props.handleChange(this.props.fromKey, this.state.valueFrom);
            });
        } else if(type === "To") {
            this.setState({valueTo: value}, () => {
                this.props.handleChange(this.props.toKey, this.state.valueTo);
            });
        }
    }

    render() {       

        return <div className={`field date-range-field ${this.props.className}`}>
                    <label className="filter-label">{this.props.label}</label>
                    <div className={`field`}>
                        <DateTimePickerField value={this.state.valueFrom} format={this.props.format} key={this.props.fromKey} label={this.props.translate("Common.From")} 
                            maxDate={this.state.valueTo} handleChange={(value) => this.onChange("From", value)}/>
                        <div className="range-separator"></div>
                        <DateTimePickerField value={this.state.valueTo} format={this.props.format} key={this.props.toKey} label={this.props.translate("Common.To")} 
                            minDate={this.state.valueFrom} handleChange={(value) => this.onChange("To", value)}/>
                    </div>
                </div>
    }
}

DateTimeRangeField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    fromKey: PropTypes.string.isRequired,
    toKey: PropTypes.string.isRequired,
    valueFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    valueTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    format: PropTypes.string
};

export default withLocalize(DateTimeRangeField);