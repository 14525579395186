import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';

import { page } from '../common/urlHelper';

// style
import './ErrorPage.scss';

class NotFoundPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: 0
        };
    }

    componentDidMount() {
        this.incrementCode();       
    }

    incrementCode() {
        let code = 404;

        setTimeout(() => {
            if(this.state.code < code) {
                this.setState({code: this.state.code + 3}, () => { this.incrementCode(); }); 
            } else {
                this.setState({code: code});
            }                                      
        }, 1); 
    }

    render() {
        let code = this.state.code.toString().padStart(3, "0");
        return <div className="size-whole-parent display-flex bg-grey">
            <div className="middle-container">
                <div className="error-page">
                    <div className="error-page-code">
                        <div><p>{code.charAt(0)}</p></div>
                        <div><p>{code.charAt(1)}</p></div>
                        <div><p>{code.charAt(2)}</p></div>
                    </div>
                    <div className="error-page-message">
                        <p>{this.props.translate("Error.404.Title")}</p>
                    </div>
                    <div className="error-page-buttons">
                        <Link className="button is-crif" to={page.home}>{this.props.translate("Common.Back")}</Link>
                    </div>
                </div>                
            </div>
        </div>; 
    }
}

export default withLocalize(NotFoundPage);