import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from "react-localize-redux";
import Moment from 'react-moment';
import 'moment-timezone';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Event, EventManager } from '../../../managers/EventManager';
import { apiGet, checkSuccessResponse, getErrorMessage, getLanguage } from '../../../store/apiWrapper';
import { writeErr } from '../logger';

import "./TableComponent.scss";

class TableComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingBodyData: false,
            showPageSizeDropdown: false,
            headerKeys: [],
            headerData: null,
            columnTypes: [],
            bodyData: null,
            total: 0,
            page: 1,
            count: this.props.size ? this.props.size : this.props.pageSizeList[0],
            orderBy: null,
            orderType: null,
            selectedRow: -1,
            collapsedRow: -1
        };

        this.handleResizeWindow = this.handleResizeWindow.bind(this);
        this.loadingTableData = this.loadingTableData.bind(this);
        this.eventLoadingTableData = this.eventLoadingTableData.bind(this);
        this.loadingTableBody = this.loadingTableBody.bind(this);
        this.eventLoadingTableBody = this.eventLoadingTableBody.bind(this);
    };

    handleResizeWindow() {
        if(this.scrollBarRef) {
            this.scrollBarRef.updateScroll();
        }        
    }

    componentDidMount(){
        this.setState({page: 1, headerKeys: []}, () => {
            this.loadingTableData();
        }); 
        window.addEventListener("resize", this.handleResizeWindow);
        EventManager.addGlobalEvent(Event.tableReload, this.eventLoadingTableData);
        EventManager.addGlobalEvent(Event.tableReloadBody, this.eventLoadingTableBody);
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResizeWindow);
        EventManager.removeGlobalEvent(Event.tableReload, this.eventLoadingTableData);
        EventManager.removeGlobalEvent(Event.tableReloadBody, this.eventLoadingTableBody);
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props
        if(oldProps.params !== newProps.params && oldProps.type === newProps.type) {
            this.setState({page: 1}, () => {
                this.loadingTableBody();
            });            
        } else if (oldProps.type !== newProps.type) {
            this.setState({page: 1, headerKeys: []}, () => {
                this.loadingTableData();
            }); 
        }
    }

    eventLoadingTableBody() {
        this.setState({page: 1}, () => {
            this.loadingTableBody();
        }); 
    }

    eventLoadingTableData() {
        this.setState({page: 1, headerKeys: []}, () => {
            this.loadingTableData();
        });
    }

    loadingTableData(){
        if(this.props.onLoad) {
            this.props.onLoad(false);
        }
        
        apiGet(window.hostApiUrl + this.props.tableUrl["header"][this.props.type], undefined, resInner => {
            if(!checkSuccessResponse(resInner)) {
                writeErr(getErrorMessage(resInner));
                return;
            }

            let data = resInner["data"]["Columns"];
            let columnTypes = [];

            data.map((item, key) => {
                this.state.headerKeys.push(item.key);
                columnTypes[item.key] = item.type;
                return null;
            });

            this.setState({headerData: data, columnTypes: columnTypes}, () => this.loadingTableBody());           
        }, err => {
            writeErr(getErrorMessage(err));
        });
    };

    loadingTableBody(){
        if(this.state.loadingBodyData){
            return;
        }

        this.resetSelectRow();        
        this.setState({loadingBodyData: true}, () => {
            if(this.props.onLoad) {
                this.props.onLoad(false);
            }
            
            let urlData = window.hostApiUrl + this.props.tableUrl["data"][this.props.type];
            let params = {
                Count: this.state.count,
                Page: this.state.page                
            }
            let filterParams = JSON.parse(JSON.stringify(this.props.params || {}));
            Object.keys(filterParams).forEach((key) => (filterParams[key] === undefined || filterParams[key] === null || filterParams[key] === 0) && delete filterParams[key]);            
            params = Object.assign(params, filterParams);
            if(this.state.orderBy !== null && this.state.orderType !== null){
                let orderParams = {
                    OrderBy: this.state.orderBy,
                    OrderType: this.state.orderType
                };
                params = Object.assign(params, orderParams);
            }

            apiGet(urlData, params, resInner => {
                if(!checkSuccessResponse(resInner)) {
                    writeErr(getErrorMessage(resInner));
                    return;
                }

                let data = resInner["data"]["items"];
                let total = resInner["data"]["total"];
                this.setState({bodyData: data, total: total, loadingBodyData: false}, () => {
                    if(this.props.onLoad) {
                        this.props.onLoad(true);
                    }
                });
            }, 
            err => {
                writeErr(getErrorMessage(err));
            });
        });        
    }

    handlePreviousPage(){
        if(this.state.page !== 1){
            this.setState({page: this.state.page - 1}, () => this.loadingTableBody());
        }
    }

    handleNextPage(){
        if(this.state.page * this.state.count < this.state.total) {
            this.setState({page: this.state.page + 1}, () => this.loadingTableBody());
        }        
    }

    handleSelectPage(page){
        if(page !== this.state.page){
            this.setState({page: page}, () => this.loadingTableBody());
        }        
    }

    handleClickPageSizeDropdown(){
        this.setState({showPageSizeDropdown: !this.state.showPageSizeDropdown});
    }

    handleSelectPageSize(count){
        this.setState({count: count, showPageSizeDropdown: false, page: 1}, () => this.loadingTableBody());
    }

    handleClosePageSizeDropdown(){
        if(this.state.showPageSizeDropdown) {
            this.setState({showPageSizeDropdown: false});
        }
    }

    handleSortClick(orderBy){
        let orderType = "asc";

        if(orderBy === this.state.orderBy){
            if(this.state.orderType === "asc"){
                orderType = "desc";
            } else if(this.state.orderType === "desc"){
                orderType = null;
                orderBy = null;
            }
        }
        this.setState({orderBy: orderBy, orderType: orderType}, () => this.loadingTableBody());
    }

    handleSelectRow(rowId, item, event) {  
        if(event && event.isDefaultPrevented()) {
            return;
        }

        if(this.props.onSelect !== undefined) {
            if(this.state.selectedRow === rowId) {
                this.resetSelectRow();
                return;
            }
            this.setState({selectedRow: rowId});
            if(this.state.collapsedRow !== -1 && rowId !== this.state.collapsedRow){
                this.setState({collapsedRow: -1});
            }
            this.props.onSelect(item.Id, item.OptionalData);
        }       
    }

    handleCollapseDetailRow(rowId, item, event) {
        if(rowId === this.state.collapsedRow) {
            this.setState({collapsedRow: -1});            
        } else {
            this.setState({collapsedRow: rowId, selectedRow: -1}, () => {
                this.handleSelectRow(rowId, item);
            });
        }
        event.preventDefault();
    }

    resetSelectRow() {
        if(this.props.onSelect !== undefined) {
            this.setState({selectedRow: -1, collapsedRow: -1});
            this.props.onSelect(0);
        }
    }

    renderTableHeader(){
        return this.state.headerData.map((item, index) => {
            return  <th className={` ${item.nav !== null ? "clickable-header" : ""}`} key={item.key} data-key={item.key} onClick={item.nav !== null ? () => this.handleSortClick(item.nav) : null}>                        
                        <div className={`table-header-with-tooltip ${this.state.orderBy !== item.nav ? "table-header-with-sort" : ""}`}>
                            {item.title != null ? <div className="tooltip table-header-tooltip" data-tooltip={item.title}></div> : null}
                            {item.name}
                        </div>
                        {this.state.orderBy === item.nav ? this.renderSortHeader() : ""}                        
                    </th>
        });
    };

    renderSortHeader(){
        if(this.state.orderType === "asc"){
            return <i className="fa fa-sort-amount-asc sort-icon"></i>
        } else if(this.state.orderType === "desc") {
            return <i className="fa fa-sort-amount-desc sort-icon"></i>
        }

        return null;
    }

    renderTableBody(){
        return this.state.bodyData.map((item, indexRow) => {
            let list = [];
            let headerRow =  <tr key={indexRow} onClick={(e) => this.handleSelectRow(indexRow, item, e)} className={`${item.Class ? item.Class : ""} ${this.state.selectedRow === indexRow ? "is-selected" : ""}`}>{
                this.state.headerKeys.map((key, index) => {
                    if(key === "collapse") {
                        if(!item.hasOwnProperty("TableDetailRow") || item.TableDetailRow.Data.length === 0) {
                            return <td className="collapse-cell" key={index}></td>
                        }

                        return <td className="collapse-cell" key={index} onClick={(e) => this.handleCollapseDetailRow(indexRow, item, e)}>
                                <div className="display-flex">
                                    <i className={`center fa fa-angle-right ${this.state.collapsedRow === indexRow ? "fa-down" : ""}`}></i>
                                </div>
                            </td>
                    } else {
                        if (this.state.headerData.find(i => i.key === key).isHtml) {
                            return <td key={index} dangerouslySetInnerHTML={{ __html: this.formatCell(item[key], this.state.columnTypes[key]) }} />
                        } else {
                            return <td key={index}>{this.formatCell(item[key], this.state.columnTypes[key])}</td>
                        }
                    }                    
                })
            }</tr>
            list.push(headerRow);

            if(item.hasOwnProperty("TableDetailRow") && item.TableDetailRow.Data.length > 0) {
                list.push(<tr className={`detail-row ${this.state.collapsedRow === indexRow ? "" : "hide-row"}`} key={`detail_${indexRow}`}><td colSpan={this.state.headerKeys.length}>{this.renderTableDetailRow(item.TableDetailRow.Header, item.TableDetailRow.Data)}</td></tr>);
            }

            return list;
        })
    }

    renderTableDetailRow(headerData, bodyData) {
        let header = [];
        let types = {};

        let keys = headerData.map((value, index) => {
            header.push(<th key={`DetailHeaderCellRow_${index}`}>{value.name}</th>);
            types[value.key] = value.type;
            return value.key;
        });        

        let body = bodyData.map((value, indexRow) => {
                        return <tr key={`DetailRow_${indexRow}`}>{
                            keys.map((key, index) => {
                                return <td key={`DetailCellRow_${index}`}>{this.formatCell(value[key], types[key])}</td>
                            })
                        }</tr>
                    });

        return (<table className="table is-hoverable">
                    <thead>
                        <tr>
                            { header }
                        </tr>
                    </thead>
                    <tbody>
                        { body }
                    </tbody>                    
                </table>);
    }

    formatCell(value, type){
        if(value || value === 0) {
            switch(type) {
                case "date":
                    return <Moment format="L">{value}</Moment>
                case "time":
                    return <Moment format="LT">{value}</Moment>
                case "datetime":
                    return <React.Fragment><Moment format="L">{value}</Moment><Moment format="LT">{value}</Moment></React.Fragment>
                case "datetimesecond":
                    return <React.Fragment><Moment format="L">{value}</Moment><Moment format="LTS">{value}</Moment></React.Fragment>
                case "bool":
                    if(value) {
                        return <i className="fa fa-check"></i>
                    } else {
                        return <i className="fa fa-times"></i>
                    }
                default:
                  return value;
              }
        }

        return null;
    }

    renderPaginationList(){
        let current = this.state.page;
        let last = Math.ceil(this.state.total / this.state.count);
        let delta = 1;
        let left = current - delta;
        let right = current + delta + 1;
        let range = [];
        let rangeWithDots = [];
        var l;

        for (let i = 1; i <= last; i++) {
            if (i === 1 || i === last || (i >= left && i < right)) {
                range.push(i);
            }
        }

        range.map((i, index) =>{
            if (l) {
                if (i - l !== 1) {
                    rangeWithDots.push(<li key={`dots_${i}`}>
                        <span className="pagination-ellipsis">…</span>
                    </li>);
                }
            }
            rangeWithDots.push(<li key={`links_${i}`}>
                <div className={`my-button pagination-link ${i === current ? "active" : ""}`} onClick={() => this.handleSelectPage(i)}>{i.toLocaleString(getLanguage())}</div>
            </li>);
            l = i;
            return null;
        });

        return rangeWithDots;
    }

    renderPageSizeList(){
        return this.props.pageSizeList.map((item, index) => {
            return (<div className="dropdown-item" key={index} onClick={() => this.handleSelectPageSize(item)}>{item}</div>);
        });
    }

    render(){
        if(this.state.headerData == null || this.state.bodyData == null) {
            return (<div className="size-whole-parent display-flex">
                        <div className="middle-container">
                            {this.props.loading || null}
                        </div>
                    </div>)
        }

        let from = this.state.page === 1 ? (this.state.total ? 1 : 0) : ((this.state.page - 1) * this.state.count) + 1;
        let to = this.state.page * this.state.count >= this.state.total ? this.state.total : this.state.page * this.state.count;

        return (
            <div className="my-table">
                <nav className="level table-title">
                    <div className="level-left">
                        <h6 className="level-item showing-title">
                            {`${this.props.translate("Table.Showing")} ${from.toLocaleString(getLanguage())} ${this.props.translate("Table.To")} ${to.toLocaleString(getLanguage())} ${this.props.translate("Table.Of")} ${this.state.total.toLocaleString(getLanguage())}`}
                        </h6>
                    </div>

                    <div className="level-right">
                        <p className="level-item showing-title">{this.props.translate("Table.Show")}</p>                        
                        <div className={`level-item dropdown page-size-dropdown ${this.state.showPageSizeDropdown ? "is-active" : ""}`}>
                            <div className="dropdown-trigger">
                                <button className="button my-button page-size-button" aria-haspopup="true" aria-controls="dropdown-menu3" onClick={() => this.handleClickPageSizeDropdown()}>
                                    <span>{this.state.count}</span>
                                    <span className="icon is-small">
                                        <i className="fa fa-angle-down"></i>
                                    </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu3" role="menu">
                                <div className="dropdown-content">
                                    {this.renderPageSizeList()}
                                </div>
                            </div>
                            {this.state.showPageSizeDropdown ? <div className="container-close" onClick={() => this.handleClosePageSizeDropdown()}></div> : ""}                            
                        </div>
                    </div>
                </nav>
                <PerfectScrollbar className="quickview-fullwidth" ref={(ref) => this.scrollBarRef = ref} options={{ wheelSpeed: 0.6, suppressScrollY: true }}>
                    <table className={`table is-hoverable ${this.props.onSelect !== undefined ? "is-selectable" : ""}`}>
                        <thead>
                            <tr>
                                {this.renderTableHeader()}
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableBody()}
                        </tbody>                    
                    </table>
                </PerfectScrollbar>
                <nav className={`pagination is-small ${this.state.total <= this.state.count ? "hide" : ""}`}>
                    <div className={`my-button pagination-previous ${this.state.page === 1 ? "is-disabled" : ""}`} onClick={() => this.handlePreviousPage()}><i className="fa fa-chevron-left"></i></div>
                    <div className={`my-button pagination-next ${this.state.page * this.state.count >= this.state.total ? "is-disabled" : ""}`} onClick={() => this.handleNextPage()}><i className="fa fa-chevron-right"></i></div>
                    <ul className="pagination-list">
                        {this.renderPaginationList()}   
                    </ul>                 
                </nav>
                {this.state.loadingBodyData ? <div className="loading-body main-container"><div className="middle-container">{this.props.loading || null}</div></div> : ""}
            </div>
        );
    };
}

TableComponent.propTypes = {
    tableUrl: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    pageSizeList: PropTypes.array,
    params: PropTypes.object,
    loading: PropTypes.element,
    onSelect: PropTypes.func,
    onLoad: PropTypes.func,
    size: PropTypes.number    
};

TableComponent.defaultProps = {
    pageSizeList: [25, 50, 100]
};

export default withLocalize(TableComponent);