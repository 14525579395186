import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";

import { userContextActions } from './store/userContextStore'
import { getLanguage } from './store/apiWrapper';
import Loading from './components/common/loading/Loading';

import Moment from 'moment'
import ReactMoment from 'react-moment';
import 'moment/locale/sk';
import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import { momentLocaleMap } from './components/common/helper';

/** Layouts **/
import LoginLayoutRoute from "./components/LoginLayout";
import DashboardLayoutRoute from "./components/dashboard/DashboardLayoutRoute";

/** Components **/
import LoginPage from './components/login/LoginPage';
import LostPwdPage from './components/login/LostPwdPage';
import SelectAppPage from './components/selectApp/SelectAppPage';
import HomePage from './components/dashboard/home/HomePage';
import HomeReportPage from './components/reports/home/HomeReportPage';
import NotFoundPage from './components/errors/NotFoundPage';

import { page } from './components/common/urlHelper';
import { writeErr } from './components/common/logger';

const onMissingTranslation = ({ translationId }) => `-${translationId}-`;

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadedLocalize: false
        };

        const defLang = getLanguage();
        this.props.initialize({
            languages: [
                { name: "Slovensky", code: "sk-SK" },
                { name: "Česky", code: "cs-CZ" },
                { name: "Deutsch", code: "de-DE" },
                { name: "English", code: "en-US" }
            ],
            options: { onMissingTranslation, defaultLanguage: defLang, renderToStaticMarkup }
        });
        let momentLang = momentLocaleMap[defLang] || 'en-gb';
        //momentLang = "sk"; // this row only for test sk locale
        Moment.locale(momentLang);
        ReactMoment.globalLocale = momentLang;
        
    }

    async componentDidMount() {
        await this.props.fetchTranslations(getLanguage(), this.props.addTranslationForLanguage)
                .then(() => this.setState({loadedLocalize: true}))
                .catch((reason) => {
                    writeErr(`Download localization for language ${getLanguage()} end with error.`);
                });
    }

    render() {
        return (  this.state.loadedLocalize ? 
            <Switch>
                <Route exact path="/">
                    <Redirect to={page.home} />
                </Route>
                <LoginLayoutRoute path={page.login} component={LoginPage} />
                <LoginLayoutRoute path={page.lostPassword} component={LostPwdPage} />
                <LoginLayoutRoute path={page.selectApp} component={SelectAppPage} />                
                <DashboardLayoutRoute path={page.home} component={HomePage}/>
                <DashboardLayoutRoute path={page.reports} component={HomeReportPage}/>
                <Route path={page.pageNotFound} component={NotFoundPage} />
                <Route> 
                    <Redirect to={page.pageNotFound} /> 
                </Route>
            </Switch>
        :   <div className="size-whole-parent display-flex">
                <Loading className="middle-container"></Loading>
            </div>            
        );
    }
}

App.propTypes = {
    fetchTranslations: PropTypes.func.isRequired
};

export default withRouter(withLocalize(connect(
    state => state.userContext,
    dispatch => bindActionCreators(userContextActions, dispatch)
)(App)));