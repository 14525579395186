import React from 'react';

import CribisSkLogo from '../../img/logo/Cribis.sk.svg';
import NeosLogo from '../../img/logo/logo_NEOS.gif';
import iRatingLogo from '../../img/logo/iRating.svg';

import CrifLogo from '../../img/logo/crif.png';

class Logo extends React.Component {

    render(){
        let img = <img src={CrifLogo} alt={this.props.alt} className={this.props.class}/>;

        if(this.props.idKey === "10"){
            img = <img src={CribisSkLogo} alt={this.props.alt} className={this.props.class}/>;
        } else if(this.props.idKey === "20"){
            img = <img src={NeosLogo} alt={this.props.alt} className={this.props.class}/>;
        } else if(this.props.idKey === "30"){
            img = <img src={iRatingLogo} alt={this.props.alt} className={this.props.class}/>;
        }

        return (img);
    }
}

export default Logo;