import React from 'react';
import PropTypes from 'prop-types';
import { Event, EventManager } from '../../../../managers/EventManager';

// style
import './CollapseList.scss';

class CollapseList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {  
            collapsed: this.props.collapsed === undefined,
            ordered: this.props.ordered || false,
            rightClickId: null
        };
    }    

    // UNSAFE_componentWillMount = props => {
    //     this.clickTimeout = null
    // }

    componentDidMount(){
        this.clickTimeout = null

        EventManager.addGlobalEvent(Event.hideContextMenu, this.eventHideContextMenu);
    }  

    componentWillUnmount() {
        EventManager.removeGlobalEvent(Event.hideContextMenu, this.eventHideContextMenu);
    }

    eventHideContextMenu = () => {
        this.setState({rightClickId: null});
    }

    handleCollapseList(event) {
        if(event.isDefaultPrevented()) {
            return;
        }
 
        this.setState({collapsed: !this.state.collapsed}, () => {
            if(this.props.onCollapse !== undefined){
                this.props.onCollapse(this.props.header, this.state.collapsed);
            }
        });

        event.preventDefault();
    }

    handleHeaderClick(event) {
        if(event.isDefaultPrevented()) {
            return;
        }

        this.handleClickItem(null, 0, null, this.props.levelType, this.props.headerId, this.props.header);        
    }

    handleClickItem(parentType, parentId, parentName, type, id, name) {        
        if(this.props.clickItem !== undefined) {
            this.props.clickItem(parentType, parentId, parentName, type, id, name);
        }
    }

    handleDoubleClickItem(id) {
        if(this.props.doubleClickItem !== undefined) {
            this.props.doubleClickItem(this.props.type, id);
        }
    }

    handleRightClick(event, id) {
        if(this.props.rightClickItem !== undefined) {
            EventManager.callGlobalEvent(Event.hideContextMenu);
            event.preventDefault();
            event.persist();
            this.setState({rightClickId: id}, () => {
                this.props.rightClickItem(event, this.props.type, id);
            });            
        }
    }

    handleClick(id, name) {
        if (this.clickTimeout !== null) {
            this.handleDoubleClickItem(id);

            clearTimeout(this.clickTimeout)
            this.clickTimeout = null
        } else {
            this.clickTimeout = setTimeout(()=>{
            this.handleClickItem(this.props.levelType, this.props.headerId, this.props.header, this.props.type, id, name);

            clearTimeout(this.clickTimeout)
            this.clickTimeout = null
            }, 175)
        }
    }

    render() {
        let icon = null;
        if(this.props.headerIcon !== undefined) {
            icon = <i className={this.props.headerIcon}></i>;
        }
        let iconItem = null;
        if(this.props.itemIcon !== undefined) {
            iconItem = <i className={this.props.itemIcon}></i>;
        }

        let listItems = null;
        if(this.props.list !== undefined) {
            // end item
            listItems = [];
            if(this.state.ordered) {
                let sortedList = Object.keys(this.props.list).sort((a,b) => this.sortFunction(a,b))
                for (let index in sortedList) {
                    let key = sortedList[index];
                    let id = this.props.list[key].id;
                    listItems.push(<li onClick={() => this.handleClick(parseInt(id), key)} onContextMenu={(e) => this.handleRightClick(e, id)} key={`${this.props.header}_${key}_${id}`} 
                                    className={`end-item ${iconItem !== null ? "item-with-icon" : ""} ${this.props.clickItem !== undefined ? "clickable" : ""}
                                    ${this.props.type === this.props.selectedItemType && parseInt(id) === this.props.selectedItemId ? "active-item" : ""} ${this.state.rightClickId === id ? "hover-item" : ""}`}>
                                        {iconItem}{key}
                                    </li>);
                }
                
            } else {
                for (let [id, object] of Object.entries(this.props.list)) {
                    listItems.push(<li onClick={() => this.handleClick(parseInt(id), object["name"])} onContextMenu={(e) => this.handleRightClick(e, id)} key={`${this.props.header}_${object["name"]}_${id}`} 
                                    className={`end-item ${iconItem !== null ? "item-with-icon" : ""} ${this.props.clickItem !== undefined ? "clickable" : ""}
                                    ${this.props.type === this.props.selectedItemType && parseInt(id) === this.props.selectedItemId ? "active-item" : ""} ${this.state.rightClickId === id ? "hover-item" : ""}`}>
                                        {iconItem}{object["name"]}
                                    </li>);
                }
            }            
        } else {  
            listItems = [];
            if(this.state.ordered) {
                let sortedList = Object.keys(this.props.children).sort((a,b) => this.sortFunction(a,b));
                for (let index in sortedList) {
                    let key = sortedList[index];
                    let child = this.props.children[key];
                    listItems.push(<li key={`item_list_${index}`}>{iconItem}{child}</li>);
                }
            } else {
                listItems = this.props.children.map((item, index) => {
                    return (<li key={`item_list_${index}`}>{iconItem}{item}</li>);
                });
            }       
        }

        return (<div className={`accordion-navbar ${icon !== null ? "with-icon" : ""}`}>
            <p className={`${this.props.levelType === this.props.selectedItemType && this.props.headerId === this.props.selectedItemId ? "active-item" : ""}`} 
                onClick={(e) => this.handleHeaderClick(e)}>
                    <span onClick={(e) => this.handleCollapseList(e)} className="collapse-button"><i className={`fa fa-angle-right ${this.state.collapsed ? "" : "fa-down"}`}></i></span>                
                {icon}{this.props.header}
            </p>
            <ul className={`${this.state.collapsed ? "" : "active-list"}`}>
                {listItems}                
            </ul>
        </div>);
    }

    sortFunction(a, b) {
        if(Number.isInteger(a) && Number.isInteger(b)){
            if (a < b) {return -1;}
            if (a > b) {return 1;}
            return 0;
        }

        a = a.toLowerCase();
        b = b.toLowerCase();
        if (a < b) {return -1;}
        if (a > b) {return 1;}
        return 0;
    }
}

CollapseList.propTypes = {
    header: PropTypes.string.isRequired,
    headerId: PropTypes.number,
    list: PropTypes.object,
    levelType: PropTypes.string,
    type: PropTypes.string,
    headerIcon: PropTypes.string,
    itemIcon: PropTypes.string,
    clickItem: PropTypes.func,
    doubleClickItem: PropTypes.func,
    rightClickItem: PropTypes.func,
    onCollapse: PropTypes.func
};

export default CollapseList;