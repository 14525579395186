import React from 'react';
import { withLocalize } from "react-localize-redux";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { writeDebug } from './common/logger';
import Logo from './common/logo';

import { setLanguage, getLanguage } from '../store/apiWrapper';
import { userContextActions } from '../store/userContextStore';
import { dashboardContextActions } from '../store/dashboardStore';

// style
import './TopNavBar.scss';
import menu from '../img/icon/menu.png';
import profil from '../img/icon/identityImage.svg';


class TopNavBar extends React.Component {
    constructor(props) {
        super(props);    
        this.state = {
            userName: this.props.userContext.authentication.profile.username,
            appGroups: this.props.userContext.authentication.appIds
        };
    }

    handleChangePassword() {
        this.props.showChangePassword(this.state.username);
    }

    handleMyProfile() {
        this.props.showUser(this.props.userContext.authentication.profile.id, true, (user) => {
            if(user.defaultLanguage !== getLanguage()) {
                setLanguage(user.defaultLanguage);

                // check if localize is downloaded
                let downloadLocalize = true;
                let array = this.props.localize.languages;
                for(let i = 0; i < array.length; i++){
                    if(array[i].code === user.defaultLanguage && this.props.localize.translations[Object.keys(this.props.localize.translations)[0]][i]) {
                        downloadLocalize = false;
                        break;
                    }
                }

                if(downloadLocalize) {
                    this.props.switchTranslation(user.defaultLanguage, this.props.addTranslationForLanguage, () => {
                        this.props.setActiveLanguage(user.defaultLanguage);                    
                    });
                } else {
                    this.props.setActiveLanguage(user.defaultLanguage);
                }
            } 
            
            this.props.updateProfile({
                name: user.name, 
                surname: user.surname, 
                email: user.email,
                agentLanguage: user.defaultLanguage
            });
        });
    }

    handleSignOut() { 
        localStorage.removeItem("selectApp");
        this.props.logout();
    }    

    selectApp(key, app){
        var data = { key: key, app: app}
        localStorage.setItem('selectApp', JSON.stringify(data))
        writeDebug(`Selected app: ${data.app},  id: ${data.key}`);
        this.props.updateSelectApp(data);
        this.props.redirectToPage("/");
    }

    handleClickSelectApp(key, app){
        this.selectApp(key, app);
    }

    renderAppLink = () => {
        var keys = Object.keys(this.state.appGroups);

        let apps = []
    
        // Outer loop to create parent
        for (let i = 0; i < keys.length; i++) {
            if(keys[i] === this.props.selectApp.key){
                continue;
            }
            var data = { key: keys[i], app: this.state.appGroups[keys[i]]}
            apps.push(
                <div className="column app-item" key={data.key}>
                    <div className="hover-link" data-key={data.key} data-app={data.app} onClick={(e) => this.handleClickSelectApp(e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-app'))}>
                        <div className="app-link">
                            <Logo idKey={keys[i]} alt={`logo ${this.state.appGroups[keys[i]]}`} class={"logo-small"}></Logo>
                        </div>
                    </div>                                        
                </div>
            );
        }
        return apps
    }

    render(){
        if(this.props.selectApp === undefined || this.props.selectApp === null){
            return (<div></div>);
        }
        return (
            <nav className="navbar" aria-label="main navigation">              
                <div id="navbarBasicExample" className="navbar-menu">
                    <div className="navbar-start">
                        <div className={`navbar-item has-dropdown ${Object.keys(this.state.appGroups).length > 1 ? "is-hoverable" : ""}`}>
                            <div className="navbar-link right is-arrowless">
                                <img src={menu} alt="menu icon"/>
                            </div>
            
                            <div className="navbar-dropdown">
                                <div className="columns app-list">
                                    {this.renderAppLink()}                                    
                                </div>
                            </div>
                        </div>

                        <div className="navbar-brand">
                            <div className="navbar-item">
                                <Logo idKey={this.props.selectApp.key} alt={`logo ${this.props.selectApp.app}`} class={"logo"}></Logo>
                            </div>
                        </div>
                    </div>
            
                    <div className="navbar-end">                        
                        <div className="navbar-item"><i className="fa fa-cog"></i></div>                        
                        <div className="navbar-item"><i className="fa fa-question"></i></div>                        

                        <div className="navbar-item is-right has-dropdown is-hoverable">
                            <div className="navbar-link right is-arrowless">
                                <img src={profil} alt="profil icon" className="profil-icon profil-icon-navbar" />
                            </div>
            
                            <div className="navbar-dropdown profil-dropdown">
                                <div className="profil-detail-container">
                                    <div className="profile-icon-container">
                                        <img src={profil} alt="profil icon" className="profil-icon profil-icon-detail" />
                                    </div>
                                    <div className="profile-info-container">
                                        <div className="profil-info">{this.props.userContext.authentication.profile.surname + " " + this.props.userContext.authentication.profile.name}</div>
                                        <div className="profil-info">{this.state.userName}</div>
                                    </div>
                                </div>
                                <div className="navbar-item" onClick={() => this.handleChangePassword()}>
                                    {this.props.translate("PswdChange.SubmitBtn")}
                                </div>
                                <div className="navbar-item" onClick={() => this.handleMyProfile()}>
                                    {/* <i className="fa fa-id-card-o"></i> */}
                                    {this.props.translate("DashboardLayout.MyProfile")}
                                </div> 

                                <hr className="navbar-divider"/>

                                <div className="navbar-item font-blue" onClick={() => this.handleSignOut()}>
                                    {this.props.translate("DashboardLayout.SignOut")}
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );          
    };

    
}

export default withLocalize(connect(
    state => ({userContext: state.userContext, localize: state.localize}),
    dispatch => bindActionCreators(Object.assign({}, userContextActions, dashboardContextActions), dispatch)
)(TopNavBar));