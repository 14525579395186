import React from 'react';
import { withLocalize } from "react-localize-redux";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as qs from 'query-string';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { reportContextActions } from '../../../store/reportStore';
import { userContextActions } from '../../../store/userContextStore';
import { dashboardContextActions } from '../../../store/dashboardStore';
import Loading from '../../common/loading/Loading';
import { page } from '../../common/urlHelper';
import LoginHistoryReport from '../loginHistory/LoginHistoryReport';
import CribisReports from '../cribis/CribisReports';
import NeosReports from '../neos/NeosReports';
import IRatingReports from '../iRating/IRatingReports';

import './HomeReportPage.scss';
import '../../dashboard/actionBar/ActionBar.scss';
import { writeErr } from '../../common/logger';

class HomeReportPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: null
        };

        this.props.updateSettings({loadingPage: false});

        let queryParams = qs.parse(this.props.location.search);
        if (!queryParams.type) {
            writeErr("Type of report is not defined in url.");
            return;
        } else {
            this.state = { type: queryParams.type };
            let params = JSON.parse(JSON.stringify(queryParams))
            delete params.type;
            this.props.updateReportSettings({ type: queryParams.type, params: params });            
        }
    }

    handleBack() {
        this.props.redirectToPage(page.home);
    }

    render() {
        if (this.state.type === null) {
            return (<div className="size-whole-parent display-flex"><div className="middle-container"><Loading></Loading></div></div>);
        }

        let report = null;
        let title = null;
        if (this.state.type === page.reportTypes.loginHistory) {
            title = this.props.translate("Report.Title.LoginHistory");
            report = <LoginHistoryReport></LoginHistoryReport>
        } else if (this.state.type === page.reportTypes.cribisReports) {
            title = this.props.translate("Report.Title.CribisReports");
            report = <CribisReports></CribisReports>
        } else if (this.state.type === page.reportTypes.neosReports) {
            title = this.props.translate("Report.Title.NeosReports");
            report = <NeosReports></NeosReports>
        }else if (this.state.type === page.reportTypes.iRatingReports) {
            title = this.props.translate("Report.Title.IRatingReports");
            report = <IRatingReports></IRatingReports>
        }

        return (<div className="size-whole-parent">
            <div className="report-action-bar">
                <div className="report-header">
                    <span className="report-title">{title}</span>
                </div>
                <div key="action-back" onClick={() => this.handleBack()} className={`action-button right`}>
                    <i className="fa fa-angle-left fa-2"></i>
                    <span>{this.props.translate("Common.Button.Back")}</span>
                </div>
            </div>
            <div className="report-dashboard">
                <PerfectScrollbar options={{ wheelSpeed: 0.6, suppressScrollX: true }}>
                    {report}
                </PerfectScrollbar>
            </div>
        </div>);
    }
}

export default withLocalize(connect(
    state => state.reportContext,
    dispatch => bindActionCreators(Object.assign({}, userContextActions, reportContextActions, dashboardContextActions), dispatch)
)(HomeReportPage));