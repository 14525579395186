import React from 'react';
import { Route } from 'react-router-dom';

const LoginLayout = ({ children }) => (
        <div className="login-body">
            {children}
        </div>
    );

const LoginLayoutRoute = ({ component: Component, componentProps, ...rest }) => {
    return (
        <Route {...rest} render={(props) => (
            <LoginLayout>
                <Component {...props} {...componentProps} />
            </LoginLayout>
        )} />
    )
};

export default LoginLayoutRoute;  
