export const url = {
    userTableHeader: (appId) => {
        return `/api/agenda/table/${appId}/users/columns`;
    },
    userTableData: (appId) => {
        return `/api/agenda/table/${appId}/users/data`;
    },
    branchTableHeader: (appId) => {
        return `/api/agenda/table/${appId}/branches/columns`;
    },
    branchTableData: (appId) => {
        return `/api/agenda/table/${appId}/branches/data`;
    },
    organizationTableHeader: (appId) => {
        return `/api/agenda/table/${appId}/organizations/columns`;
    },
    organizationTableData: (appId) => {
        return `/api/agenda/table/${appId}/organizations/data`;
    },
    appInfoData: (appId) => {
        return `/api/agenda/info/${appId}/data`;
    },
    appCustomerData: (appId) => {
        return `/api/agenda/info/${appId}/customerData`;
    },
    // Branch
    getBranch: (appId, id) => {
        return `/api/agenda/${appId}/branches/${id}`;
    },
    addBranch: (appId) => {
        return `/api/agenda/${appId}/branches`;
    },
    editBranch: (appId, id) => {
        return `/api/agenda/${appId}/branches/${id}`;
    },
    deleteBranch: (appId, id) => {
        return `/api/agenda/${appId}/branches/${id}`;
    },
    // User
    getUser: (appId, id) => {
        return `/api/agenda/${appId}/users/${id}`;
    },
    addUser: (appId) => {
        return `/api/agenda/${appId}/users`;
    },
    editUser: (appId, id) => {
        return `/api/agenda/${appId}/users/${id}`;
    },
    deleteUser: (appId, id) => {
        return `/api/agenda/${appId}/users/${id}`;
    },
    // Roles
    getRoles: (appId, orgId, branchId) => {
        if (orgId !== undefined && branchId !== undefined) {
            return "";
        }
        return `/api/agenda/info/${appId}/roles` + (orgId !== undefined ? `?orgId=${orgId}` : "") + (branchId !== undefined ? `?branchId=${branchId}` : "");
    },
    // Reports
    passwordHistoryTableHeader: (appId) => {
        return `/api/report/table/${appId}/passwordhistory/columns`;
    },
    passwordHistoryTableData: (appId) => {
        return `/api/report/table/${appId}/passwordhistory/data`;
    },
    loginHistoryTableHeader: (appId) => {
        return `/api/report/table/${appId}/loginhistory/columns`;
    },
    loginHistoryTableData: (appId) => {
        return `/api/report/table/${appId}/loginhistory/data`;
    },
    cribisReportsTableHeader: (appId) => {
        return `/api/report/table/${appId}/cribisreports/columns`;
    },
    cribisReportsTableFilter: (appId) => {
        return `/api/report/table/${appId}/cribisreports/filter`;
    },
    cribisReportsTableData: (appId) => {
        return `/api/report/table/${appId}/cribisreports/data`;
    },
    cribisReportsExportExcelData: (appId) => {
        return `/api/report/table/${appId}/cribisreports/excel`;
    },
    cribisReportsExportCsvData: (appId) => {
        return `/api/report/table/${appId}/cribisreports/csv`;
    },
    neosReportsTableHeader: (appId) => {
        return `/api/report/table/${appId}/neosreports/columns`;
    },
    neosReportsTableFilter: (appId) => {
        return `/api/report/table/${appId}/neosreports/filter`;
    },
    neosReportsTableData: (appId) => {
        return `/api/report/table/${appId}/neosreports/data`;
    },
    neosReportsExportExcelData: (appId) => {
        return `/api/report/table/${appId}/neosreports/excel`;
    },
    neosReportsExportCsvData: (appId) => {
        return `/api/report/table/${appId}/neosreports/csv`;
    },
    iRatingReportsTableHeader: (appId) => {
        return `/api/report/table/${appId}/iratingreports/columns`;
    },
    iRatingReportsTableFilter: (appId) => {
        return `/api/report/table/${appId}/iratingreports/filter`;
    },
    iRatingReportsTableData: (appId) => {
        return `/api/report/table/${appId}/iratingreports/data`;
    },
    iRatingReportsExportExcelData: (appId) => {
        return `/api/report/table/${appId}/iratingreports/excel`;
    },
    iRatingReportsExportCsvData: (appId) => {
        return `/api/report/table/${appId}/iratingreports/csv`;
    },
    neosReportsAccountInfo: (appId) => {
        return `/api/report/table/${appId}/neosreports/account-info`;
    },
};

export function TableUrl(appId) {
    this.appId = appId;
    this.header = {
        user: url.userTableHeader(appId),
        branch: url.branchTableHeader(appId),
        organization: url.organizationTableHeader(appId),
        passwordhistory: url.passwordHistoryTableHeader(appId),
        loginhistory: url.loginHistoryTableHeader(appId),
        cribisreports: url.cribisReportsTableHeader(appId),
        neosreports: url.neosReportsTableHeader(appId),
        iratingreports: url.iRatingReportsTableHeader(appId)
    };
    this.data = {
        user: url.userTableData(appId),
        branch: url.branchTableData(appId),
        organization: url.organizationTableData(appId),
        passwordhistory: url.passwordHistoryTableData(appId),
        loginhistory: url.loginHistoryTableData(appId),
        cribisreports: url.cribisReportsTableData(appId),
        neosreports: url.neosReportsTableData(appId),
        iratingreports: url.iRatingReportsTableData(appId)
    };
    this.filter = {
        cribisreports: url.cribisReportsTableFilter(appId),
        neosreports: url.neosReportsTableFilter(appId),
        iratingreports: url.iRatingReportsTableFilter(appId)
    };
    this.exportExcel = {
        cribisreports: url.cribisReportsExportExcelData(appId),
        neosreports: url.neosReportsExportExcelData(appId),
        iratingreports: url.iRatingReportsExportExcelData(appId)
    };
    this.exportCsv = {
        cribisreports: url.cribisReportsExportCsvData(appId),
        neosreports: url.neosReportsExportCsvData(appId),
        iratingreports: url.iRatingReportsExportCsvData(appId)
    };
    this.accountInfo = {
        neosreports: url.neosReportsAccountInfo(appId),
    }
}

export const page = {
    login: "/login",
    lostPassword: "/lost-password",
    selectApp: "/select-app",
    home: "/home",
    reports: "/reports",
    pageNotFound: "/page-not-found",
    getReports: function (type, params) {
        let url = `${this.reports}?type=${type}`;
        if (params) {
            Object.keys(params).forEach((key, index) => {
                url += `&${key}=${params[key]}`;
            });
        }
        return url;
    },
    reportTypes: {
        loginHistory: "loginHistory",
        cribisReports: "cribisReports",
        neosReports: "neosReports",
        iRatingReports: "iRatingReports"
    }
}