import React from 'react';
import { withLocalize } from "react-localize-redux";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { dashboardContextActions } from '../../../store/dashboardStore';
import { userContextActions } from '../../../store/userContextStore';
import { page } from '../../common/urlHelper';
import { app } from '../../common/appHelper';

// style
import './ActionBar.scss';
import { writeDebug } from '../../common/logger';

class ActionBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleResetPasswordButton: false,
            toggleReportsButton: false
        };
    }

    selectedItem() {
        return this.props.bodyParams.bodyType !== null && this.props.bodyParams.selectedId !== 0;
    }

    handleCreate() {
        if (this.props.bodyParams.bodyType !== null) {
            if (this.props.bodyParams.bodyType === "user") {
                this.props.showUser(0);
            } else if (this.props.settings.useBranch && this.props.bodyParams.bodyType === "branch") {
                this.props.showBranch(0);
            }
        } else {
            writeDebug("Can not create undefined type record.")
        }
    }

    handleDelete() {
        if (this.selectedItem()) {
            if (this.props.bodyParams.bodyType === "user") {
                this.props.deleteUser(this.props.bodyParams.selectedId);
            } else if (this.props.settings.useBranch && this.props.bodyParams.bodyType === "branch") {
                this.props.deleteBranch(this.props.bodyParams.selectedId);
            }
        }
    }

    handleUpdate() {
        if (this.selectedItem()) {
            if (this.props.bodyParams.bodyType === "user") {
                this.props.showUser(this.props.bodyParams.selectedId);
            } else if (this.props.settings.useBranch && this.props.bodyParams.bodyType === "branch") {
                this.props.showBranch(this.props.bodyParams.selectedId);
            }
        }
    }

    handleResetPassword() {
        if (this.selectedItem() && this.props.bodyParams.bodyType === "user") {
            let user = this.props.customerData.users[this.props.bodyParams.selectedId];
            this.props.resetPassword(user.userName, user.email);
        }
    }

    handleToggleResetPasswordButton() {
        if (this.selectedItem()) {
            this.setState({ toggleResetPasswordButton: !this.state.toggleResetPasswordButton });
        }
    }

    handleToggleReportsButton() {
        this.setState({ toggleReportsButton: !this.state.toggleReportsButton });
    }

    handleReports(type) {
        if (this.selectedItem()) {
            this.props.redirectToPage(page.getReports(type, { userId: this.props.bodyParams.selectedId }));
            // if (page.reportTypes.loginHistory === type || (page.reportTypes.cribisReports === type && this.props.settings.appId === app.MultiCribis)) {
            //     this.props.redirectToPage(page.getReports(type, { userId: this.props.bodyParams.selectedId }));
            // }
        } else {
            this.props.redirectToPage(page.getReports(type));
            //if (page.reportTypes.cribisReports === type && this.props.settings.appId === app.MultiCribis) {
            //    this.props.redirectToPage(page.getReports(type));
            //}
        }
    }

    renderResetPasswordButton() {
        return (
            <div key="action-reset-password" onClick={() => this.handleToggleResetPasswordButton()} className={`column is-narrow`}>
                <div className={`dropdown action-button ${this.selectedItem() ? "" : "is-disable"} ${this.state.toggleResetPasswordButton ? "is-active" : ""}`}>
                    <div className="action-dropdown">
                        <i className="fa fa-key"></i>
                        <span>{this.props.translate("Common.Button.Security")}</span>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </div>
                    <div className="dropdown-menu" id="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                            <div onClick={() => this.handleResetPassword()} className="dropdown-item">
                                <span>{this.props.translate("User.Button.ResetSecurity")}</span>
                            </div>
                        </div>
                    </div>
                    {this.state.toggleResetPasswordButton ? <div className="container-close" onClick={() => this.handleToggleResetPasswordButton()}></div> : ""}
                </div>
            </div>
        );
    }

    renderReportButton() {
        let items = [];
        if (this.props.settings.appId === app.MultiCribis) {
            items.push(<div key="report-action-cribisReport" onClick={() => this.handleReports(page.reportTypes.cribisReports)} className="dropdown-item">
                <span>{this.props.translate("Report.Title.CribisReports")}</span>
            </div>);
        }
        if (this.props.settings.appId === app.NEOS) {
            items.push(<div key="report-action-neosReport" onClick={() => this.handleReports(page.reportTypes.neosReports)} className="dropdown-item">
                <span>{this.props.translate("Report.Title.NeosReports")}</span>
            </div>);
        }
        if (this.props.settings.appId === app.IRating) {
            items.push(<div key="report-action-iRatingReport" onClick={() => this.handleReports(page.reportTypes.iRatingReports)} className="dropdown-item">
                <span>{this.props.translate("Report.Title.IRatingReports")}</span>
            </div>);
        }

        if (this.selectedItem()) {
            items.push(<div key="report-action-loginHistory" onClick={() => this.handleReports(page.reportTypes.loginHistory)} className="dropdown-item">
                <span>{this.props.translate("Report.Title.LoginHistory")}</span>
            </div>);
        }

        return (<div key="action-reports" onClick={() => items.length !== 0 ? this.handleToggleReportsButton() : null} className={`column is-narrow`}>
            <div className={`dropdown action-button ${items.length !== 0 ? "" : "is-disable"} ${this.state.toggleReportsButton ? "is-active" : ""}`}>
                <div className="action-dropdown">
                    <i className="fa fa-area-chart"></i>
                    <span>{this.props.translate("Common.Button.Reports")}</span>
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                        {items}
                    </div>
                </div>
                {this.state.toggleReportsButton ? <div className="container-close" onClick={() => this.handleToggleReportsButton()}></div> : ""}
            </div>
        </div>);
    }

    render() {
        let actions = [];
        // for user and branch
        if (this.props.bodyParams.bodyType !== "organization") {
            actions.push(<div key="action-new" onClick={() => this.handleCreate()} className={`column is-narrow action-button`}>
                <i className="fa fa-plus"></i>
                <span>{this.props.translate("Common.Button.New")}</span>
            </div>);

            actions.push(<div key="action-delete" onClick={() => this.handleDelete()} className={`column is-narrow action-button ${this.selectedItem() ? "" : "is-disable"}`}>
                <i className="fa fa-trash"></i>
                <span>{this.props.translate("Common.Button.Delete")}</span>
            </div>);

            actions.push(<div key="action-update" onClick={() => this.handleUpdate()} className={`column is-narrow action-button ${this.selectedItem() ? "" : "is-disable"}`}>
                <i className="fa fa-pencil"></i>
                <span>{this.props.translate("Common.Button.Update")}</span>
            </div>);
        }

        // only for user
        if (this.props.bodyParams.bodyType === "user") {
            actions.push(this.renderResetPasswordButton());
            actions.push(this.renderReportButton());
        }

        return (actions);
    }
}

export default withLocalize(connect(
    state => state.dashboardContext,
    dispatch => bindActionCreators(Object.assign({}, userContextActions, dashboardContextActions), dispatch)
)(ActionBar));