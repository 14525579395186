import React from 'react';
import { LOCATION_CHANGE } from 'react-router-redux';
import { apiGet, apiPost, parseQuery, getErrorMessage, checkSuccessResponse } from './apiWrapper';
import { updateObject } from './storeFunctions'
import { push } from 'react-router-redux'
import { toast } from 'react-toastify';
import { Translate } from "react-localize-redux";
import { writeDebug } from '../components/common/logger';
import { isFunction } from 'util';

const requestGetUserContextType = "REQUEST_USER_CONTEXT";
const receiveGetUserContextType = "RECEIVE_USER_CONTEXT";
const toggleNavMenuType = "TOGGLE_NAVMENU";
const userLogin = "USER_LOGIN";
const userLoginReset = "USER_LOGIN_RESET";
const userLogout = "USER_LOGOUT";
const userLoginRequest = "USER_LOGIN_REQUEST";
const userLoginFailed = "USER_LOGIN_FAILED";
const userExpired = "LOGIN_EXPIRED";
const updateUserProfile = "UPDATE_USER_PROFILE";
const initAuth = { isAuthorized: false, error:undefined, loading:false };
const initialState = restoreFromStorage({  collapsed: true, authentication: {...initAuth} });

//action creator for redux
export const userContextActions = {
    toggle: () => async (dispatch, getState) => {
       dispatch({type:toggleNavMenuType, data:{ collapsed: !getState().userContext.collapsed } });
    },
    fetchTranslations: (language, addTranslationForLanguage, callback) => async (dispatch,getState) => {
        writeDebug("Loading translations");
        return apiGet(`/lang/${language}`, undefined, res => {
            addTranslationForLanguage(res, language);
            if(isFunction(callback)) {
                callback();
            }
        })
    },
    switchTranslation: (language, addTranslationForLanguage, setLanguage) => async (dispatch) => {
        writeDebug(`Switch language to ${language}`);
        dispatch(userContextActions.fetchTranslations(language, addTranslationForLanguage, () => {
            if(isFunction(setLanguage)) {
                setLanguage();
            }            
        }));
    },
    login: (username, pswd, url) => async(dispatch, getState) => {
        if(getState().userContext.authentication.loading) {
            return;
        }
        dispatch({type:userLoginRequest});
        apiPost(window.hostApiUrl + `/api/account/login`, undefined, {username:username, password:pswd, grant_type:'password'}, res => {
            if(!checkSuccessResponse(res)) {
                dispatch({type:userLoginFailed, data: res.data});
                toast.warn(getErrorMessage(res));
            } else {
                dispatch({type:userLogin, data: res.data});
                redirectTo(dispatch, url);
            }
        }, err => {
            writeDebug('login error', err);
            //toast.error(err.data.error.message);
        });
    },
    logout: () => async(dispatch, getState) => {
        localStorage.removeItem('auth');
        dispatch({type:userLogout})
        redirectTo(dispatch, '/');
    },
    resetPassword: (username, email) => async() => {
        apiPost(window.hostApiUrl + '/api/account/reset-pwd', undefined, {userName: username, email: email}, (res) => {
            if(checkSuccessResponse(res)) {
                toast.success(<Translate id="PwdReset.ResetSuccess">Password was successfully reset and will be delivered soon by e-mail.</Translate>);
            } else {
                toast.warn(getErrorMessage(res));
            }            
        }, (err) => {
            toast.error(getErrorMessage(err));
        });
    },
    changePassword: (data, setSubmitting, success) => async () => {        
        apiPost(window.hostApiUrl + '/api/account/change-pwd', undefined, data, (res) => {
            if(checkSuccessResponse(res)) {
                toast.success(<Translate id="PswdChange.ChangeSuccess">Password was changed.</Translate>);
                if(isFunction(success)){
                    success();
                }
            } else {
                toast.warn(getErrorMessage(res));
            }            
        }, (err) => {
            toast.error(getErrorMessage(err));
        }, () => {
            setSubmitting(false);
        });
    },
    updateProfile: (userData) => async (dispatch, getState) => {
        let profile = getState().userContext.authentication.profile;
        mapObject(userData, profile, true);
        dispatch({type: updateUserProfile, data: profile});
    },
    redirectBack: (url) => async(dispatch, getState) => redirectTo(dispatch, url),
    resetLoginAttempt: () => async(dispatch, getState) => dispatch({type:userLoginReset}),
    redirectToPage: (link) => async(dispatch, getState) => {
        dispatch(push(link));
    }
};

// store reducer
export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case requestGetUserContextType:
        case receiveGetUserContextType:
        case toggleNavMenuType:
            return updateObject(state, action.data);
        case LOCATION_CHANGE:
            return { ...state, location: action.payload.pathname };
        case userLoginRequest:
            return updateObject(state, { authentication: updateObject(state.authentication, { loading:true, error:undefined }) });
        case userLogin:
            let dataul = { isAuthorized:action.data.success, ...action.data, loading:false };
            localStorage.setItem('auth', JSON.stringify(dataul));
            return updateObject({...state, isLoaded:false, isLoading:false }, { authentication: updateObject(state.authentication, dataul) });
        case userLoginFailed:
            let dataulf = { isAuthorized:action.data.success, ...action.data, loading:false };
            return updateObject({...state, isLoading:false }, { authentication: updateObject(state.authentication, dataulf) })
        case userLogout:
            return { ...state, navItems: [], authentication: {...initAuth}};
        case userLoginReset:
            return updateObject(state, { authentication: updateObject(state.authentication, { error:undefined }) });
        case userExpired:
            localStorage.removeItem('auth');
            break;
        case updateUserProfile:
            let auth = JSON.parse(localStorage.getItem('auth'));
            auth.profile = action.data;
            localStorage.setItem('auth', JSON.stringify(auth));
            return updateObject(state, { authentication: updateObject(state.authentication, { profile: action.data})})
        default:
            return state;
    }
}

function mapObject(obj, mappedObject, onlyContainsFields) {
    Object.keys(obj).forEach( function(key) {
        if (typeof obj[key] === 'object') {
            mapObject(obj[key], mappedObject);
        }
        else {
            if(onlyContainsFields && !mappedObject.hasOwnProperty(key)) {                
            } else {
                mappedObject[key] = obj[key];
            }            
        }
    } );
}

function restoreFromStorage(initial) {
    let auth = localStorage.getItem('auth');

    if(auth !== null && auth !== undefined) { 
        auth = JSON.parse(auth); 
        if(auth === null || auth === undefined) {
            return initial;
        }

        if(Date.parse(auth.expire) < Date.now()) {
            localStorage.removeItem('auth');
            return initial;
        }
    }
    return updateObject(initial, { authentication: updateObject(initial.authentication, {...auth, loading:false}) });
}

function redirectTo(dispatch, url) {
    const q = parseQuery(url);
    const redir = q.returnUrl !== null && q.returnUrl !== undefined ? decodeURIComponent(q.returnUrl) : '/';
    writeDebug("Redirecting to " + redir);
    dispatch(push(redir));
}