import React from 'react';
import { withLocalize } from "react-localize-redux";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ActionBar from '../actionBar/ActionBar';
import LeftNavBar from '../leftNavBar/LeftNavBar';
import { dashboardContextActions } from '../../../store/dashboardStore';
import TableComponent from '../../common/table/TableComponent';
import Loading from '../../common/loading/Loading';
import { TableUrl } from '../../common/urlHelper';

// style
import './NeosPage.scss';
import '../home/HomePage.scss';
import { writeErr } from '../../common/logger';

class NeosPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tableParams: {}
        }

        this.handleDetailItem = this.handleDetailItem.bind(this);
        this.handleResizeWindow = this.handleResizeWindow.bind(this);

        this.props.resetParams();

        this.props.updateSettings({ useBranch: false, useOrganization: true });
    }

    handleResizeWindow() {
        this.scrollBarRef.updateScroll();
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResizeWindow);
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResizeWindow);
    }

    handleDetailItem(type, id) {
        if (this.props.settings.useBranch && type === "branch") {
            this.props.showBranch(id);
        } else if (type === "user") {
            this.props.showUser(id);
        } else {
            writeErr(`undefined type: ${type} for detail`);
            return;
        }
    }

    render() {
        let tableUrl = new TableUrl(this.props.appId);
        const loading = <Loading></Loading>;
        return (
            <div className="columns work-container">
                <div className="column left-navbar-container">
                    <LeftNavBar loading={loading} onDoubleClickItem={this.handleDetailItem}></LeftNavBar>
                </div>
                <div className="column right-body-container">
                    <div className="action-bar columns">
                        <ActionBar></ActionBar>
                    </div>
                    <div className="dashboard">
                        <PerfectScrollbar className="quickview-fullwidth" ref={(ref) => this.scrollBarRef = ref} options={{ wheelSpeed: 0.6, suppressScrollX: true }}>
                            <div className="dashboard-table size-whole-parent">
                                <TableComponent tableUrl={tableUrl} type={this.props.bodyParams.bodyType} onSelect={this.props.selectBodyItem}
                                    params={this.props.bodyParams.data} loading={loading}></TableComponent>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalize(connect(
    state => state.dashboardContext,
    dispatch => bindActionCreators(dashboardContextActions, dispatch)
)(NeosPage));