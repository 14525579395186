import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from "react-localize-redux";
import 'moment-timezone';
import { Formik, Form, Field } from 'formik';

import "./FilterTableComponent.scss";
import { apiGet, apiFileDownload, checkSuccessResponse, getErrorMessage } from '../../../store/apiWrapper';
import TableComponent from '../table/TableComponent';
import { writeErr } from '../logger';
import SelectComponent from '../select/SelectComponent';

import DateTimeRangeField from '../formikFields/DateTimeRangeField';
import DateTimePickerField from '../formikFields/DateTimePickerField';

class FilterTableComponent extends React.Component {
    _isMounted = false;    

    constructor(props) {
        super(props);

        this.state = {
            filterMetaData: null,
            params: {},
            initParams: this.props.initParams,
            allParams: Object.assign({}, this.props.params, this.props.initParams),
            activeFilter: this.props.activeFilter || true,
            loading: false,
            exporting: false,
            account: null
        };        
    };

    componentDidMount(){
        this._isMounted = true;
        this.loadingTableFilter();
        if(this.props.type === "neosreports")
            this.loadingInfo();
    };

    componentDidUpdate(oldProps) {
        let allParamsNew = Object.assign({}, this.props.params, this.state.params);
        let allParamsOld = Object.assign({}, oldProps.params, this.state.params);
        if(JSON.stringify(allParamsNew) !== JSON.stringify(allParamsOld)) {
            this.setState({allParams: allParamsNew});            
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadingInfo() {
        apiGet(window.hostApiUrl + this.props.tableUrl["accountInfo"][this.props.type], undefined, res => {
            if(!checkSuccessResponse(res)) {
                writeErr(getErrorMessage(res));
                return;
            }          

            this.setState({account: res["data"]});
        }, err => {
            writeErr(getErrorMessage(err));
        });
    }

    loadingTableFilter() {
        apiGet(window.hostApiUrl + this.props.tableUrl["filter"][this.props.type], this.state.initParams, res => {
            if(!checkSuccessResponse(res)) {
                writeErr(getErrorMessage(res));
                return;
            }          

            this.setState({filterMetaData: res["data"]["Fields"]});
        }, err => {
            writeErr(getErrorMessage(err));
        });
    }

    handleLoading = (loaded) => {
        if (this._isMounted) {
            this.setState({loading: !loaded});
        }        
    }

    handleExport(event, values) {
        event.preventDefault();
        if(this.state.exporting) {
            return;
        }

        this.setState({exporting: true}, () => {
            let filterParams = JSON.parse(JSON.stringify(values || {}));
            Object.keys(filterParams).forEach((key) => (filterParams[key] === undefined || filterParams[key] === null || filterParams[key] === 0) && delete filterParams[key]); 
            let params = Object.assign({}, this.props.params, filterParams);
            let query = this.props.exportCsv ? this.props.tableUrl["exportCsv"][this.props.type] : this.props.tableUrl["exportExcel"][this.props.type];
            apiFileDownload(query, params, 
                () => {
                    if(this._isMounted) {
                        this.setState({exporting: false});
                    }
                }
            );               
        });     
    }

    renderFilter() {
        if(!this.state.filterMetaData) {
            return null;
        }

        let init = {};
        this.state.filterMetaData.map((item, index) => {
            if(item.Value.field){
                if(item.Value.field.defaultValue) {
                    init[item.Key] = item.Value.field.defaultValue;
                }                 
            } else {
                if(item.Value.from.defaultValue) {
                    init[item.Value.from.key] = item.Value.from.defaultValue;
                }
                if(item.Value.to.defaultValue) {
                    init[item.Value.to.key] = item.Value.to.defaultValue;
                }
            }
            return null;
        })

        return <Formik
            initialValues={init}

            onSubmit={(values, { setSubmitting }) => {
                if(this.state.loading) {
                    setSubmitting(false);
                    return;
                }

                this.setState({params: values, allParams: Object.assign({}, this.props.params, values)}, () => {
                    setSubmitting(false);
                });                
            }}

            render={({values, isSubmitting, setFieldValue, handleSubmit, handleReset, resetForm}) => (<Form>
                    <div className="filter">
                        {this.state.filterMetaData.map((item, index) => {
                            return this.getField(index, item.Key, item.Value, values, setFieldValue);
                        })}
                    </div> 
                    <div className="buttons">
                        <div className="right size-whole-parent">
                            <button className={`button is-primary is-outlined ${this.state.loading ? "is-loading" : ""}`} type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                                <i className="fa fa-search"></i>
                                {this.props.translate("Common.Search")}
                            </button>
                            <button className="button" type="button" onClick={() => resetForm({})} disabled={isSubmitting}>
                                <i className="fa fa-undo"></i>
                                {this.props.translate("Common.Reset")}
                            </button>
                            <button className={`button ${this.state.exporting ? "is-loading" : ""}`} type="button" onClick={(e) => this.handleExport(e, values)} disabled={isSubmitting}>
                                <i className="fa fa-file-excel-o"></i>
                                {this.props.translate("Common.Export")}
                            </button>
                        </div>
                    </div>                                   
                </Form>
            )}
        />   
    }

    getField(index, key, item, values, setFieldValue) {
        let fieldElement = null;
        if(item.field) {
            let field = item.field;
            switch (field.type.toLowerCase()) {  
                case "selectlist":
                    let options = field.data.map((i) => {
                        return {value: i.Id.toString(), label: i.Label}
                    });
                    fieldElement = <div key={field.key}>
                        <label className="filter-label">{field.name}</label>
                        <SelectComponent value={values[field.key] ? [values[field.key].toString()] : []}  
                            options={options} enableSearch={true} resetable={true} handleChange={(value) => setFieldValue(field.key, value[0])}></SelectComponent>
                    </div>
                    break;
                case "date":
                case "datetime":
                case "datetimesecond":
                        fieldElement = <div key={field.key}>
                        <label className="filter-label">{field.name}</label>
                        <DateTimePickerField value={values[field.key]} format={field.type.toLowerCase()}
                            handleChange={(value) => setFieldValue(field.key, value)}/>
                    </div>
                    break;     
                default:
                    // default string filter
                    fieldElement = <div key={field.key}>
                        <label className="filter-label">{field.name}</label>
                        <div className="input-with-reset-button">
                            <Field value={values[field.key] || ""} type="text" name={field.key} className="input filter-input" />
                            <div className="reset-button" onClick={() => setFieldValue(field.key, undefined)}></div>
                        </div>
                    </div> 
                    break;
            }

            
        } else {
            fieldElement = this.getFromToField(index, key, item, values, setFieldValue);
        }        

        return <div className="filter-field" key={`filter_field_${index}`}>
                    {fieldElement}
                </div>
    }

    getFromToField(index, key, item, values, setFieldValue) {
        let field = null;
        switch (item.from.type.toLowerCase()) {
            case "date":
            case "datetime":
            case "datetimesecond":
                field = <DateTimeRangeField valueFrom={values[item.from.key]} valueTo={values[item.to.key]} key={key} id={key} format={item.from.type.toLowerCase()}
                            label={item.from.name} fromKey={item.from.key} toKey={item.to.key} handleChange={(type, value) => setFieldValue(type, value)}/>;            
                break;     
            default:
                writeErr(`Type "${item.from.type}" is not implemented for filter from to field.`); 
                break;
        }

        return field;
    }

    renderInfo() {
        return (
            <div className="credit">
                {`${this.props.translate("Report.Remaining.Credit")} ${this.state.account.credit} ${this.state.account.unit}.`}
            </div>
        )
    }

    render() {
        return <div className="my-table-with-filter">
            <div className={`my-table-filter-header ${this.state.activeFilter ? "active-filter" : ""}`} onClick={() => this.setState({activeFilter: !this.state.activeFilter})}>
                <p className="subtitle is-4">{this.props.translate("Common.Filter")}</p>
                <i className="fa fa-2 fa-angle-right"></i>
            </div>
            <div className={`my-table-filter ${this.state.activeFilter ? "active-filter" : ""}`}>                
                {this.renderFilter()}
                {this.state.account !== null && this.renderInfo()}         
            </div>            
            <TableComponent tableUrl={this.props.tableUrl} type={this.props.type} pageSizeList={this.props.pageSizeList} onSelect={this.props.onSelect} 
                size={this.props.size} params={this.state.allParams} loading={this.props.loading} onLoad={this.handleLoading}></TableComponent>            
        </div>
    }
}

FilterTableComponent.propTypes = {
    tableUrl: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    pageSizeList: PropTypes.array,
    filter: PropTypes.object.isRequired,
    params: PropTypes.object,
    loading: PropTypes.element,
    onSelect: PropTypes.func,
    size: PropTypes.number,
    activeFilter: PropTypes.bool,
    updateUrl: PropTypes.bool
};

export default withLocalize(FilterTableComponent);