import React from 'react';
import { withLocalize } from "react-localize-redux";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reportContextActions } from '../../../store/reportStore';

import TableComponent from '../../common/table/TableComponent';
import Loading from '../../common/loading/Loading';
import { TableUrl } from '../../common/urlHelper';

class LoginHistoryReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        let tableUrl = new TableUrl(this.props.dashboardContext.settings.appId);

        const loading = <Loading></Loading>;
        return <>
            <div className="section">
                <h2 className="section-title subtitle is-4">{this.props.translate("Report.Title.PasswordHistory")}</h2>
                <div className="">
                    <TableComponent tableUrl={tableUrl} type={"passwordhistory"} size={5} pageSizeList={[5, 10, 20]} params={this.props.reportContext.settings.params} loading={loading}></TableComponent>
                </div>
            </div>
            <div className="section">
                <h2 className="section-title subtitle is-4">{this.props.translate("Report.Title.LoginHistory")}</h2>
                <div className="">
                    <TableComponent tableUrl={tableUrl} type={"loginhistory"} size={10} pageSizeList={[10, 25, 50, 100]} params={this.props.reportContext.settings.params} loading={loading}></TableComponent>
                </div>
            </div>
        </>;
    }
}

export default withLocalize(connect(
    state => ({ reportContext: state.reportContext, dashboardContext: state.dashboardContext }),
    dispatch => bindActionCreators(reportContextActions, dispatch)
)(LoginHistoryReport));