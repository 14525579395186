import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from 'react-sm-select';
import { withLocalize } from "react-localize-redux";

import './SelectComponent.scss';

class SelectComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value || []
        };
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props
        if(oldProps.value !== newProps.value) {
            this.setState({value: newProps.value});            
        }
    }

    clear() {
        this.setState({value: []});
    }

    formatOption(checked, option, isSingle) {
        if(option["description"] !== undefined) {
            return (<div className="option-with-description">
                <p className="option-label"><i className={`fa fa-check ${checked ? "check" : ""}`}></i>{option["label"]}</p>
                <p className="option-description">{option["description"]}</p>
            </div>)
        } else {
            return <p className="option-label"><i className={`fa fa-check ${checked ? "check" : ""}`}></i>{option["label"]}</p>;
        }
    }

    render(){
        return <div className={this.props.className}>
            {this.props.disabled !== undefined && this.props.disabled ?
                <input className="input" type="text" disabled placeholder={this.props.disablePlaceholder}/>
            :
                <MultiSelect
                    Option={({checked, option, isSingle}) => {return this.formatOption(checked, option, isSingle)}}
                    id={this.props.id !== undefined ? this.props.id : null}
                    mode="single"
                    enableSearch={this.props.enableSearch !== undefined ? this.props.enableSearch : false}
                    resetable={this.props.resetable !== undefined ? this.props.resetable : false}
                    options={this.props.options}
                    value={this.state.value}
                    onChange={value => this.props.handleChange(value)}
                    searchPlaceholder={ this.props.translate("Common.Search") }
                    allSelectedLabel={this.props.options.length > 0 ? this.props.options[0].label : ""}
                    valuePlaceholder={ this.props.translate("Common.Select") }/>
            }
        </div>;
    }
}

SelectComponent.propTypes = {
    id: PropTypes.string,
    disablePlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    enableSearch: PropTypes.bool,
    resetable: PropTypes.bool,
    options: PropTypes.array.isRequired,
    value: PropTypes.array,
    handleChange: PropTypes.func.isRequired
};

export default withLocalize(SelectComponent);