import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userContextActions } from '../../store/userContextStore';

import DashboardLayout from './DashboardLayout';

const DashboardLayoutRoute = ({ component: Component, user, componentProps, ...rest }) => (
        <Route path={rest.path} render={(props) => {
            if(user === undefined || user.authentication === undefined || !user.authentication.isAuthorized){
                return (<Redirect to={`/login?returnUrl=${encodeURIComponent(props.match.url)}`} />)
            } else {
                return (
                    <DashboardLayout>
                        <Component {...props} {...componentProps} />
                    </DashboardLayout>
                )
            }
        }} />    
);

export default connect(
    state => ({ user: state.userContext }),
    dispatch => bindActionCreators(userContextActions, dispatch)
)(DashboardLayoutRoute)